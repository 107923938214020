import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';
import { Email } from './email-chip-input.model';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { defaultEmailRegex } from 'app/shared/models/form-validation.constants';

@Component({
  selector: 'sywa-email-chip-input',
  templateUrl: './email-chip-input.component.html',
  styleUrls: ['./email-chip-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EmailChipInputComponent), multi: true }],
})
export class EmailChipInputComponent extends AbstractControlValueAccessor<Email[]> {
  @Input() disabled = false;
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE];
  hasError = false;

  @ViewChild('currentEmail') public currentEmail: NgControl;

  defaultEmailRegex = defaultEmailRegex;

  writeValue(emails: Email[]): void {
    this.hasError = false;
    super.writeValue(emails);
  }

  addEmailFromInput(event: MatChipInputEvent): void {
    this.hasError = false;
    if (event.value) {
      const emailList: string[] = event.value.trim().split(/[,;|\t\n\r ]+/);
      if (emailList.length === 1) {
        if (emailList[0] && this.currentEmail.valid) {
          this.value = this.value ? this.value.concat(emailList[0]) : emailList;
          event.chipInput.clear();
        } else {
          this.hasError = true;
        }
      } else {
        const wrongValues = emailList.map((value: string) => {
          const email = value.trim();
          if (this.validateEmail(email)) {
            this.value = this.value ? this.value.concat(email) : [email];
          } else {
            this.hasError = true;
            return email;
          }
        });
        this.currentEmail.control.setValue(wrongValues.join(' ').trim());
        super.writeValue(this.value);
        if (!this.hasError) {
          event.chipInput.clear();
        }
      }
    }
  }

  removeEmail(emailToRemove: Email): void {
    this.value = this.value.filter((email: Email) => email !== emailToRemove);
  }

  private validateEmail(email: string): boolean {
    return new RegExp(defaultEmailRegex).test(email);
  }
}
