import { Injectable } from '@angular/core';
import { ProjectCreateRequestVm, ProjectUpdateRequestVm, ProjectVm } from 'app/shared/api';
import { ProjectForm } from './project-form';
import { DOCUMENT_SUBTYPE_PHOTO_IDENTITY, DOCUMENT_TYPE_PHOTO_IDENTITY } from './project-form.constant';

@Injectable({
  providedIn: 'root',
})
export class ProjectFormMapper {
  fromProjectVm(project: ProjectVm): ProjectForm {
    if (project) {
      return {
        allowedEmploymentTypes: project.allowedEmploymentTypes,
        clientId: project.client?.id,
        country: project.country,
        dateEnd: project.dateEnd,
        dateStart: project.dateStart,
        location: project.location,
        maxDepth: project.maxDepth,
        name: project.name,
        number: project.number,
        type: project.type,
        description: project.description,
        optionalDocumentTypes: project.optionalDocumentTypes,
        requestedDocumentTypes: project.requestedDocumentTypes,
        isPhotoIdentityRequired: project.requestedDocumentTypes?.includes(DOCUMENT_TYPE_PHOTO_IDENTITY),
        transmissionDelay: project.transmissionDelay,
        toleranceDelay: project.toleranceDelay,
      };
    }
  }

  toProjectCreateRequestVm(projectForm: ProjectForm): ProjectCreateRequestVm {
    if (projectForm) {
      const startDate = new Date(projectForm.dateStart);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(projectForm.dateEnd);
      endDate.setHours(23, 59, 0, 0);

      return {
        allowedEmploymentTypes: projectForm.allowedEmploymentTypes,
        clientId: projectForm.clientId.toString(),
        country: projectForm.country,
        dateEnd: endDate.toISOString(),
        dateStart: startDate.toISOString(),
        location: projectForm.location,
        maxDepth: projectForm.maxDepth,
        name: projectForm.name,
        number: projectForm.number,
        type: projectForm.type,
        description: projectForm.description,
        requestedDocumentTypes: projectForm.isPhotoIdentityRequired ? [DOCUMENT_SUBTYPE_PHOTO_IDENTITY] : undefined,
        transmissionDelay: projectForm.transmissionDelay,
        toleranceDelay: projectForm.toleranceDelay,
      };
    }
  }

  toProjectUpdateRequestVm(projectForm: ProjectForm): ProjectUpdateRequestVm {
    if (projectForm) {
      const project: ProjectUpdateRequestVm = {
        dateEnd: projectForm.dateEnd,
        dateStart: projectForm.dateStart,
        location: projectForm.location,
        maxDepth: projectForm.maxDepth,
        name: projectForm.name,
        number: projectForm.number,
        description: projectForm.description,
        optionalDocumentTypes: projectForm.optionalDocumentTypes,
        requestedDocumentTypes: projectForm.requestedDocumentTypes ? projectForm.requestedDocumentTypes : [],
        transmissionDelay: projectForm.transmissionDelay,
        toleranceDelay: projectForm.toleranceDelay,
        allowedEmploymentTypes: projectForm.allowedEmploymentTypes,
      };

      // FIX sywa-2064: remove potential duplicate requestedDocumentTypes
      project.requestedDocumentTypes = [...new Set(project.requestedDocumentTypes)];

      if (
        projectForm.isPhotoIdentityRequired &&
        !project.requestedDocumentTypes.includes(DOCUMENT_TYPE_PHOTO_IDENTITY)
      ) {
        project.requestedDocumentTypes.push(DOCUMENT_TYPE_PHOTO_IDENTITY);
      }
      if (
        !projectForm.isPhotoIdentityRequired &&
        project.requestedDocumentTypes.includes(DOCUMENT_TYPE_PHOTO_IDENTITY)
      ) {
        project.requestedDocumentTypes.splice(project.requestedDocumentTypes.indexOf(DOCUMENT_TYPE_PHOTO_IDENTITY), 1);
      }

      return project;
    }
  }
}
