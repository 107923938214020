import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractEditComponent } from 'app/shared/abstract-classes/abstract-edit-component';
import { OrganisationVm } from 'app/shared/api';
import { DocumentFileTypesMapper } from 'app/shared/mappers/document-file-types.mapper';
import { ResponsiveService } from 'app/shared/services/responsive.service';

@Component({
  selector: 'sywa-add-edit-organisation',
  templateUrl: './add-edit-organisation.component.html',
  styleUrls: ['./add-edit-organisation.component.scss'],
})
export class AddEditOrganisationComponent extends AbstractEditComponent {
  @Input() organisation: OrganisationVm;

  @Output() organisationAddedOrEdited = new EventEmitter<string>();

  constructor(public responsiveService: ResponsiveService, private documentsFileTypesMapper: DocumentFileTypesMapper) {
    super(documentsFileTypesMapper);
  }

  submitSucceeded(organisationId: string): void {
    this.organisationAddedOrEdited.emit(organisationId);
  }
}
