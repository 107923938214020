import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportResourceDialogComponent } from './report-resource-dialog.component';
import { PopupContainerModule } from 'app/shared/components/organisms/popup-container/popup-container.module';
import { ReportResourceFormModule } from '../report-resource-form/report-resource-form.module';
import { TwoPanelLayoutModule } from 'app/shared/components/organisms/two-panel-layout/two-panel-layout.module';

@NgModule({
  declarations: [ReportResourceDialogComponent],
  imports: [CommonModule, ReportResourceFormModule, PopupContainerModule, TwoPanelLayoutModule],
})
export class ReportResourceDialogModule {}
