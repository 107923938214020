import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconLabelsConfig, IconLabelsState } from './icon-labels.model';

@Component({
  selector: 'sywa-icon-labels',
  templateUrl: './icon-labels.component.html',
  styleUrls: ['./icon-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLabelsComponent {
  @Input() config: IconLabelsConfig;
  IconLabelsState = IconLabelsState;
}
