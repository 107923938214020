import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'app/core/models/user';
import { OrganisationVm, UserVm } from 'app/shared/api';

@Component({
  selector: 'sywa-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent {
  @Input() organisation: OrganisationVm;
  @Input() user: User;
  @Input() userVm: UserVm;

  @Output() succeeded = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  submitSucceeded(): void {
    this.succeeded.emit();
  }
  cancel(): void {
    this.canceled.emit();
  }
}
