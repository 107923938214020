import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { countryRequiredError } from './form-errors';
import { CountryCode } from './select-country.constants';

@Directive({
  selector: '[sywaRequiredCountryValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredCountryValidatorDirective,
      multi: true,
    },
  ],
})
export class RequiredCountryValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return control && (control.value as CountryCode) !== undefined ? null : countryRequiredError;
  }
}
