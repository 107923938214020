import { Navigation } from './models/navigation';
import { ROUTES } from './routes.constants';

export const NAV_CONFIGS: Navigation[] = [
  {
    id: 'operations',
    navButtonLabelKey: 'navigation.operations',
    headerTitleKey: 'navigation.operations',
    route: ROUTES.operations,
    iconName: 'sywa-operation',
  },
  {
    id: 'suppliers',
    navButtonLabelKey: 'navigation.suppliers',
    headerTitleKey: 'navigation.suppliers',
    route: ROUTES.suppliers,
    iconName: 'sywa-supplier',
  },
  {
    id: 'organisations',
    navButtonLabelKey: 'navigation.organisations',
    headerTitleKey: 'navigation.organisations',
    route: ROUTES.organisations,
    iconName: 'sywa-supplier',
  },
  {
    id: 'employees',
    navButtonLabelKey: 'navigation.employees',
    headerTitleKey: 'navigation.employees',
    route: ROUTES.employees,
    iconName: 'sywa-resource',
  },
  {
    id: 'attendees',
    navButtonLabelKey: 'navigation.attendees',
    headerTitleKey: 'navigation.attendees',
    route: ROUTES.attendees,
    iconName: 'sywa-resource',
  },
  {
    id: 'controls',
    navButtonLabelKey: 'navigation.controls',
    headerTitleKey: 'navigation.controls',
    route: ROUTES.controls,
    iconName: 'sywa-whistle',
  },
];
