import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationSnackbarComponent } from './notification-snackbar.component';
import { NotificationDocumentStatusContentModule } from '../notification-document-status-content/notification-document-status-content.module';
import { NotificationInvitationContentModule } from '../notification-invitation-content/notification-invitation-content.module';
import { MatIconModule } from '@angular/material/icon';
import { NotificationDocumentContentModule } from '../notification-document-content/notification-document-content.module';
import { NotificationDocumentExpirationContentModule } from '../notification-document-expiration-content/notification-document-expiration-content.module';
import { NotificationControlContentModule } from '../notification-control-content/notification-control-content.module';

@NgModule({
  declarations: [NotificationSnackbarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NotificationDocumentContentModule,
    NotificationDocumentStatusContentModule,
    NotificationInvitationContentModule,
    NotificationDocumentExpirationContentModule,
    NotificationControlContentModule,
  ],
  exports: [NotificationSnackbarComponent],
})
export class NotificationSnackbarModule {}
