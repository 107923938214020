import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditProjectDialogComponent } from './add-edit-project-dialog.component';
import { AddEditProjectModule } from '../add-edit-project/add-edit-project.module';
import { PopupContainerModule } from 'app/shared/components/organisms/popup-container/popup-container.module';

@NgModule({
  declarations: [AddEditProjectDialogComponent],
  imports: [CommonModule, AddEditProjectModule, PopupContainerModule],
  exports: [AddEditProjectDialogComponent],
})
export class AddEditProjectDialogModule {}
