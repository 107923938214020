import { Component, Input } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';

@Component({
  selector: 'sywa-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent {
  @Input() sidenavMode: MatDrawerMode = 'side';
  @Input() sidenavPosition: 'start' | 'end' = 'end';
  @Input() sidenavOpened = false;
}
