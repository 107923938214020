import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditResourceComponent } from './add-edit-resource.component';
import { ResourceFormModule } from '../resource-form/resource-form.module';
import { TwoPanelLayoutModule } from 'app/shared/components/organisms/two-panel-layout/two-panel-layout.module';
import { FilesViewerModule } from 'app/shared/components/organisms/files-viewer/files-viewer.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddEditResourceComponent],
  imports: [CommonModule, ResourceFormModule, TwoPanelLayoutModule, FilesViewerModule, TranslateModule],
  exports: [AddEditResourceComponent],
})
export class AddEditResourceModule {}
