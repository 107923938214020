import { Pipe, PipeTransform } from '@angular/core';
import { AuthRepository } from 'app/core/state/auth.repository';

@Pipe({
  name: 'isSameAsConnectedUserOrganisation',
})
export class IsSameAsConnectedUserOrganisationPipe implements PipeTransform {
  constructor(private authRepository: AuthRepository) {}

  transform(organisationId: string): boolean {
    return this.authRepository.getCurrentUserOrganisationSnapshot()?.id === organisationId;
  }
}
