import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationFormComponent } from './organisation-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DropzoneModule } from 'app/shared/components/organisms/dropzone/dropzone.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectCountryModule } from 'app/shared/components/molecules/select-country/select-country.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { environment } from 'src/environments/environment';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';
import { IsSameAsConnectedUserOrganisationPipeModule } from 'app/shared/pipes/is-same-as-connected-user-organisation-pipe/is-same-as-connected-user-organisation-pipe.module';
import { CompanyIdValidatorModule } from 'app/shared/validators/company-id/company-id-validator.module';
import { EmailChipInputModule } from 'app/shared/components/molecules/email-chip-input/email-chip-input.module';
import { MatIconModule } from '@angular/material/icon';

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatSlideToggleModule, MatIconModule];

@NgModule({
  declarations: [OrganisationFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    DropzoneModule,
    SelectCountryModule.forRoot(environment.defaultLanguage),
    CheckConnectedUserHasRightModule,
    CompanyIdValidatorModule,
    IsSameAsConnectedUserOrganisationPipeModule,
    EmailChipInputModule,
    ...MATERIAL_MODULES,
  ],
  exports: [OrganisationFormComponent],
})
export class OrganisationFormModule {}
