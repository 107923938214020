import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTES } from 'app/core/routes.constants';
import { OrganisationSummaryVm, OrganisationVm } from 'app/shared/api';
import { IconLabelsConfig } from 'app/shared/components/atoms/icon-labels/icon-labels.model';
import { TileConfig } from 'app/shared/components/molecules/tile/tile.model';
import getPicture, { PictureEntity } from 'app/utils/get-picture';

@Component({
  selector: 'sywa-organisation-tile',
  templateUrl: './organisation-tile.component.html',
  styleUrls: ['./organisation-tile.component.scss'],
})
export class OrganisationTileComponent implements OnInit, OnChanges {
  @Input() organisation: OrganisationVm;
  @Input() organisationSummary: OrganisationSummaryVm;
  @Input() withLink = false;

  tileConfig: TileConfig;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.organisation) {
      this.defineTileConfigFromOrganisation();
    } else if (this.organisationSummary) {
      this.defineTileConfigFromOrganisationSummary();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisation && this.organisation) {
      this.defineTileConfigFromOrganisation();
    } else if (changes.organisationSummary && this.organisationSummary) {
      this.defineTileConfigFromOrganisationSummary();
    }
  }

  private defineTileConfigFromOrganisation(): void {
    if (this.organisation) {
      const iconLabelsConfigs: IconLabelsConfig[] = [];
      if (this.organisation.temporaryEmployment) {
        iconLabelsConfigs.push({
          iconName: 'sywa-employment',
          labelsKeys: [{ key: this.translateService.instant('organisation.infos.temporary-employment') as string }],
        });
      }
      const nationalIdLabel = this.translateService.instant('organisation.infos.labels.national-id') as string;
      iconLabelsConfigs.push({
        iconName: 'sywa-location',
        labelsKeys: [{ key: this.organisation.address }],
      });

      this.tileConfig = {
        srcImg: this.organisation.picture
          ? getPicture({
              entityId: this.organisation.id,
              entityType: PictureEntity.ORGANISATION,
              pictureId: this.organisation.picture?.id,
            })
          : undefined,
        srcFallbackImg: 'assets/images/default-organisation.png',
        titleKey: this.organisation.name,
        prohibitTranslationTitleKey: true,
        subTitleKey: nationalIdLabel + ' ' + this.organisation.nationalId,
        infos: iconLabelsConfigs,
        mainLink: this.withLink ? `/${ROUTES.organisations}/${this.organisation.id}` : undefined,
      } as TileConfig;
    }
  }

  private defineTileConfigFromOrganisationSummary(): void {
    if (this.organisationSummary) {
      this.tileConfig = {
        srcImg: this.organisationSummary.picture
          ? getPicture({
              pictureId: this.organisationSummary.picture?.id,
              entityType: PictureEntity.ORGANISATION,
              entityId: this.organisationSummary.id,
            })
          : undefined,
        srcFallbackImg: 'assets/images/default-organisation.png',
        titleKey: this.organisationSummary.name,
        prohibitTranslationTitleKey: true,
      } as TileConfig;
    }
  }
}
