import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, from } from 'rxjs';
import { environment } from '../environments/environment';
import { APP_VERSION } from './app.constants';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'sywa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    @Inject(LOCALE_ID) private locale: string,
    private oauthService: OAuthService
  ) {}

  ngOnInit(): void {
    this.configureTranslations();

    if (!environment.production) {
      window.app = APP_VERSION;
    }

    this.oauthService.events
      .pipe(
        filter(
          (oAuthEvent: OAuthEvent) =>
            oAuthEvent.type === 'silently_refreshed' ||
            oAuthEvent.type === 'token_received' ||
            oAuthEvent.type === 'invalid_nonce_in_state'
        )
      )
      .subscribe((oAuthEvent: OAuthEvent) => {
        if (oAuthEvent.type === 'invalid_nonce_in_state') {
          from(this.oauthService.loadDiscoveryDocumentAndLogin()).subscribe();
        }
      });
  }

  private configureTranslations(): void {
    this.translateService.use(this.locale);
    this.translateService.setDefaultLang(this.locale);
  }
}
