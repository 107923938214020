import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageContainerComponent } from './page-container.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';

@NgModule({
  declarations: [PageContainerComponent],
  imports: [CommonModule, MatSidenavModule, AddResponsiveClassModule],
  exports: [PageContainerComponent],
})
export class PageContainerModule {}
