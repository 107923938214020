import { ActivatedRouteSnapshot } from '@angular/router';

export class RouteHelper {
  static getTargetChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.getTargetChild(route.firstChild);
    }
    return route;
  }
}
