import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgControl, NgForm } from '@angular/forms';
import { RightName } from 'app/core/rights/right-name.model';
import { RightsService } from 'app/core/rights/rights.service';
import { LoaderService } from 'app/core/services/loader.service';
import { NotificationService } from 'app/core/services/notification.service';
import { AbstractFormComponent } from 'app/shared/abstract-classes/abstract-form-component';
import {
  ErrorVm,
  OrganisationCreateRequestVm,
  OrganisationsService,
  OrganisationUpdateRequestVm,
  OrganisationVm,
} from 'app/shared/api';
import { DocumentFileMapper } from 'app/shared/mappers/document-file.mapper';
import { finalize, Observable, ObservableInput, of, switchMap, tap } from 'rxjs';
import { DEFAULT_ORGANISATION_FORM } from './organisation-form.constants';
import { OrganisationFormMapper } from './organisation-form.mapper';

@Component({
  selector: 'sywa-organisation-form',
  templateUrl: './organisation-form.component.html',
  styleUrls: ['./organisation-form.component.scss'],
})
export class OrganisationFormComponent extends AbstractFormComponent implements OnChanges {
  @Input() organisationToEdit: OrganisationVm;
  @Output() submitSucceeded = new EventEmitter<string>();

  organisationForm = DEFAULT_ORGANISATION_FORM;

  isSubmitting = false;
  isEditMode = false;
  hasBeenOfaChecked = false;
  nationalIdHasBeenChanged = false;
  isOfaSynchronized;

  RightName = RightName;

  @ViewChild('dropzonefiles') private dropzonefiles: NgControl;
  @ViewChild('addOrganisationForm') private addOrganisationForm: NgForm;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private documentFileMapper: DocumentFileMapper,
    private organisationsService: OrganisationsService,
    private organisationFormMapper: OrganisationFormMapper,
    public notificationService: NotificationService,
    private loaderService: LoaderService,
    private rightsService: RightsService
  ) {
    super(documentFileMapper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisationToEdit) {
      this.isEditMode = !!this.organisationToEdit;
      if (this.isEditMode) {
        this.retrievePicture$ = this.organisationsService.getOrganisationPicture(
          this.organisationToEdit.id,
          'response'
        );
        this.organisationForm = this.organisationFormMapper.fromOrganisationVm(this.organisationToEdit);
        this.retrievePicture();
        this.isOfaSynchronized = this.organisationToEdit.provider === 'ofa';
      }
    }
  }

  checkOfaOrganisation(): void {
    this.loaderService.open();
    const nationalId = this.organisationForm.nationalId.replace(/\s+/g, '');
    this.organisationsService
      .getOrganisationFromProvider(this.organisationForm.country, nationalId, 'body')
      .pipe(
        finalize(() => {
          this.loaderService.close();
          this.hasBeenOfaChecked = true;
          this.nationalIdHasBeenChanged = false;
        })
      )
      .subscribe({
        next: (organisationCreateRequest: OrganisationCreateRequestVm) => {
          if (this.isEditMode) {
            this.organisationForm.provider = 'ofa';
            this.organisationForm.providerId = organisationCreateRequest.providerId;
          } else {
            this.organisationForm =
              this.organisationFormMapper.fromOrganisationCreateRequest(organisationCreateRequest);
          }
          this.isOfaSynchronized = true;
        },
        error: () => {
          if (this.isEditMode) {
            this.organisationForm.provider = 'local';
            this.organisationForm.providerId = '';
          }
          this.isOfaSynchronized = false;
        },
      });
  }

  submitOrganisation(): void {
    let organisationId: string;
    this.isSubmitting = true;

    let request$: Observable<OrganisationVm>;

    if (this.isEditMode) {
      const organisationUpdateRequest = this.organisationFormMapper.toOrganisationUpdateRequestVm(
        this.organisationForm
      );
      if (
        !this.rightsService.connectedUserHasSomeRights([
          RightName.EDIT_ANY_ORGANISATION,
          RightName.EDIT_OWN_ORGANISATION,
        ])
      ) {
        delete organisationUpdateRequest.nationalId;
        delete organisationUpdateRequest.address;
      }
      if (!this.rightsService.connectedUserHasRight(RightName.EDIT_BTPCARD_REQUIRED_ANY_ORGANISATION)) {
        delete organisationUpdateRequest.btpCardRequired;
      }
      if (!this.rightsService.connectedUserHasRight(RightName.EDIT_TEMPORARY_EMPLOYMENT_ANY_ORGANISATION)) {
        delete organisationUpdateRequest.temporaryEmployment;
      }

      request$ = of(organisationUpdateRequest).pipe(
        switchMap(
          (organisationUpdateRequestVm: OrganisationUpdateRequestVm) =>
            this.organisationsService.updateOrganisation(
              this.organisationToEdit.id,
              organisationUpdateRequestVm,
              'body'
            ) as ObservableInput<OrganisationVm>
        )
      );
    } else {
      request$ = of(this.organisationFormMapper.toOrganisationCreateRequestVm(this.organisationForm)).pipe(
        switchMap(
          (organisationCreateRequestVm: OrganisationCreateRequestVm) =>
            this.organisationsService.createOrganisation(
              organisationCreateRequestVm,
              'body'
            ) as ObservableInput<OrganisationVm>
        )
      );
    }

    this.loaderService.open();
    request$
      .pipe(
        tap((organisation: OrganisationVm) => (organisationId = organisation.id)),
        switchMap(() =>
          (!this.isEditMode || this.isDropzoneDirty()) && this.documentFiles?.length
            ? this.organisationsService.putOrganisationPicture(organisationId, this.documentFiles[0].content)
            : of(void 0)
        ),
        finalize(() => {
          this.loaderService.close();
          this.isSubmitting = false;
          if (organisationId) {
            this.submitSucceeded.emit(organisationId);
            this.addOrganisationForm.form.reset();
          }
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.notifySuccess();
        },
        error: (error: HttpErrorResponse) => {
          const errorVm: ErrorVm = error.error as ErrorVm;
          this.notificationService.notifyError(errorVm.message);
        },
      });
  }

  nationalIdChanged(): void {
    this.nationalIdHasBeenChanged = true;
    this.isOfaSynchronized = undefined;
    this.organisationForm.provider = 'local';
    this.organisationForm.providerId = '';
  }

  isDropzoneDirty(): boolean {
    return this.dropzonefiles.dirty;
  }
}
