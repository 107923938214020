import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

const isEmpty = (value: string): boolean => value === undefined || value === null || value === '';

@Pipe({
  name: 'retrieveImage',
})
export class RetrieveImagePipe implements PipeTransform {
  constructor(private http: HttpClient, private oauthService: OAuthService) {}

  async transform(src: string, fallbackSrc?: string): Promise<string> {
    let source = src;
    let needsBearer = false;

    // // No source, no fallback, abort
    if (isEmpty(src) && isEmpty(fallbackSrc)) {
      return;
    }

    // If source is empty, use fallbock
    if (isEmpty(src)) {
      source = fallbackSrc;
    } else {
      if (src.match(environment.apiUrl)) {
        needsBearer = true;
      }
    }

    // Prepare header with token if exists
    const token = this.oauthService.getAccessToken();
    const headers = !isEmpty(token) && needsBearer ? new HttpHeaders({ Authorization: `Bearer ${token}` }) : null;

    return this.request(source, headers).catch(() =>
      fallbackSrc ? this.request(fallbackSrc, headers).catch(() => '') : ''
    );
  }

  protected request(src: string, headers: HttpHeaders): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(src, { headers, responseType: 'blob' }).subscribe({
        next(blob): void {
          const reader = new FileReader();
          reader.onloadend = (): void => resolve(reader.result as string);
          reader.readAsDataURL(blob);
        },
        error(): void {
          reject('Resource not available');
        },
      });
    });
  }
}
