import { UserForm } from './user-form.model';

export const DEFAULT_USER_FORM: UserForm = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  organisationId: '',
  role: undefined,
  password: '',
} as UserForm;
