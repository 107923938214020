import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditResourceDialogData, AddEditResourceDialogResult } from './add-edit-resource-dialog.model';

@Component({
  selector: 'sywa-add-edit-resource-dialog',
  templateUrl: './add-edit-resource-dialog.component.html',
  styleUrls: ['./add-edit-resource-dialog.component.scss'],
})
export class AddEditResourceDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public addResourceDialogData: AddEditResourceDialogData,
    private dialogRef: MatDialogRef<AddEditResourceDialogComponent, AddEditResourceDialogResult | undefined>
  ) {}

  close(resourceId?: string): void {
    this.dialogRef.close({ resourceId });
  }
}
