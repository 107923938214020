import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Navigation } from 'app/core/models/navigation';
import { User } from 'app/core/models/user';
import { RightName } from 'app/core/rights/right-name.model';
import { ViewRightName } from 'app/core/rights/view-right-name.model';
import { ROUTES } from 'app/core/routes.constants';
import { BroadcastService } from 'app/core/services/broadcast.service';
import { DialogService } from 'app/core/services/dialog.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { AuthRepository } from 'app/core/state/auth.repository';
import { NavigationRepository } from 'app/core/state/navigation.repository';
import { NotificationRepository } from 'app/core/state/notification.repository';
import { NotificationMapper } from 'app/features/notification/services/notification.mapper';
import { AddEditOrganisationDialogComponent } from 'app/features/organisation/sub-modules/add-edit-organisation-dialog/add-edit-organisation-dialog.component';
import { AddEditOrganisationDialogResult } from 'app/features/organisation/sub-modules/add-edit-organisation-dialog/add-edit-organisation-dialog.model';
import { InviteOrganisationDialogComponent } from 'app/features/organisation/sub-modules/invite-organisation-dialog/invite-organisation-dialog.component';
import { InviteOrganisationDialogResult } from 'app/features/organisation/sub-modules/invite-organisation-dialog/invite-organisation-dialog.model';
import { AddEditProjectDialogComponent } from 'app/features/project/sub-modules/add-edit-project-dialog/add-edit-project-dialog.component';
import { AddEditProjectDialogResult } from 'app/features/project/sub-modules/add-edit-project-dialog/add-edit-project-dialog.model';
import { AddEditResourceDialogComponent } from 'app/features/resource/sub-modules/add-edit-resource-dialog/add-edit-resource-dialog.component';
import {
  AddEditResourceDialogData,
  AddEditResourceDialogResult,
} from 'app/features/resource/sub-modules/add-edit-resource-dialog/add-edit-resource-dialog.model';
import { ReportResourceDialogComponent } from 'app/features/resource/sub-modules/report-resource-dialog/report-resource-dialog.component';
import { AddEditUserDialogComponent } from 'app/features/user/sub-modules/add-edit-user-dialog/add-edit-user-dialog.component';
import { AddEditUserDialogData } from 'app/features/user/sub-modules/add-edit-user-dialog/add-edit-user-dialog.model';
import {
  NotificationVm,
  OrganisationsService,
  OrganisationVm,
  ProjectSummaryVm,
  SupplierInvitationVm,
} from 'app/shared/api';
import { RetrieveImagePipe } from 'app/shared/pipes/retrieve-image.pipe';
import { ResponsiveService } from 'app/shared/services/responsive.service';
import getPicture, { PictureEntity } from 'app/utils/get-picture';
import { Observable, tap } from 'rxjs';
import { authCodeFlowConfig } from '../../../auth-code-flow-config.constant';

@Component({
  selector: 'sywa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [RetrieveImagePipe],
})
export class HeaderComponent implements OnInit {
  currentNavigation$: Observable<Navigation>;
  currentUser: User;
  organisation: OrganisationVm;
  organisationPictureUrl: string;
  RightName = RightName;
  ViewRightName = ViewRightName;

  nbNotifications$: Observable<number>;
  currentUser$: Observable<User>;

  organisationId: string;
  notificationsInvitation: NotificationVm[] = [];
  notificationsAlert: NotificationVm[] = [];

  @Output() openAddEditUserEmitter = new EventEmitter<void>();
  @Output() toggleMenuEmitter = new EventEmitter<void>();

  constructor(
    public responsiveService: ResponsiveService,
    private authRepository: AuthRepository,
    private oauthService: OAuthService,
    private navigationRepository: NavigationRepository,
    private router: Router,
    private dialogService: DialogService,
    private navigationService: NavigationService,
    private broadcastService: BroadcastService,
    private notificationRepository: NotificationRepository,
    private organisationsService: OrganisationsService,
    private notificationMapper: NotificationMapper
  ) {}

  ngOnInit(): void {
    this.authRepository
      .getCurrentUser()
      .pipe(
        tap((user: User) => {
          this.currentUser = user;
          this.organisation = user?.organisation;
        })
      )
      .subscribe();

    this.organisationPictureUrl = this.organisation?.picture
      ? getPicture({
          entityId: this.organisation.id,
          entityType: PictureEntity.ORGANISATION,
          pictureId: this.organisation.picture?.id,
        })
      : undefined;

    this.currentNavigation$ = this.navigationRepository.getCurrentNavigation();

    this.nbNotifications$ = this.notificationRepository.getTotalReceivedNotifications$();

    this.currentUser$ = this.authRepository.getCurrentUser();

    this.authRepository
      .getCurrentUserOrganisationId()
      .pipe(
        tap((organisationId: string) => {
          this.organisationId = organisationId;
          this.loadTopBarContent();
        })
      )
      .subscribe();
  }

  closeFirstNotification(): void {
    this.notificationsInvitation.shift();
  }

  closeFirstNotificationAlert(): void {
    this.notificationsAlert.shift();
  }

  refreshNotificationsInvitation(): void {
    this.notificationsInvitation = [];
    this.loadTopBarContent();
  }

  toggleMenu(): void {
    this.toggleMenuEmitter.emit();
  }

  openAddProjectPopup(): void {
    this.dialogService
      .open<AddEditProjectDialogComponent, undefined, AddEditProjectDialogResult>(AddEditProjectDialogComponent)
      .subscribe((result: AddEditProjectDialogResult) => {
        if (result?.projectId) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate([`${ROUTES.operations}/${result.projectId}`]);
        }
      });
  }

  openAddOrganisationPopup(): void {
    this.dialogService
      .open<AddEditOrganisationDialogComponent, undefined, AddEditOrganisationDialogResult>(
        AddEditOrganisationDialogComponent
      )
      .subscribe((result: AddEditOrganisationDialogResult) => {
        if (result?.organisationId) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate([`${ROUTES.organisations}/${result.organisationId}`]);
        }
      });
  }

  openInviteOrganisationPopup(): void {
    this.dialogService
      .open<InviteOrganisationDialogComponent, undefined, InviteOrganisationDialogResult>(
        InviteOrganisationDialogComponent
      )
      .subscribe((result: InviteOrganisationDialogResult) => {
        if (result?.invitationSent) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate([`${ROUTES.suppliers}`]);
        }
      });
  }

  openAddResourcePopup(): void {
    this.dialogService
      .open<AddEditResourceDialogComponent, AddEditResourceDialogData, AddEditResourceDialogResult>(
        AddEditResourceDialogComponent,
        {
          organisationId: this.organisation.id,
        }
      )
      .subscribe((result: AddEditResourceDialogResult) => {
        if (result?.resourceId) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate([`${ROUTES.employees}/${result.resourceId}`]);
        }
      });
  }

  openAddEditUserPopup(): void {
    this.dialogService
      .open<AddEditUserDialogComponent, AddEditUserDialogData, undefined>(AddEditUserDialogComponent, {
        organisation: this.organisation,
      })
      .subscribe();
  }

  openReportResourcePopup(): void {
    this.dialogService.open<ReportResourceDialogComponent>(ReportResourceDialogComponent).subscribe();
  }

  async navigateToCurrentUserCompanyPage(): Promise<void> {
    const currentUserCompanyId = this.authRepository.getCurrentUserSnapshot().organisation.id;
    await this.router.navigate([`${ROUTES.organisations}/${currentUserCompanyId}`]);
  }

  async navigateToResources(): Promise<void> {
    await this.router.navigate([`${ROUTES.organisations}/${this.organisation?.id}/${ROUTES.resources}`]);
  }

  async navigateToProfilePage(): Promise<void> {
    await this.router.navigate([`${ROUTES.users}/${ROUTES.profile}`]);
  }

  async navigateToNotificationPage(): Promise<void> {
    await this.router.navigate([`${ROUTES.notifications}`]);
  }

  async logout(): Promise<void> {
    this.oauthService.configure(authCodeFlowConfig);
    this.authRepository.updateUser(null);
    await this.oauthService.revokeTokenAndLogout();
    await this.oauthService.loadDiscoveryDocumentAndLogin();
    await this.oauthService.tryLogin();
  }

  private loadTopBarContent(): void {
    if (this.organisationId) {
      this.organisationsService
        .getOrganisationInvitations(this.organisationId, [SupplierInvitationVm.StatusEnum.Waiting], 'body')
        .subscribe((invitations: SupplierInvitationVm[]) => {
          invitations.forEach((invitation: SupplierInvitationVm) => {
            this.notificationsInvitation.push(this.notificationMapper.invitationToNotification(invitation));
          });
        });
      this.organisationsService
        .getProjectsNotAssigned(this.organisationId, 'body')
        .subscribe((projects: ProjectSummaryVm[]) => {
          projects.forEach((project: ProjectSummaryVm) => {
            this.notificationsAlert.push(this.notificationMapper.projectToNotification(project));
          });
        });
      this.organisationsService.getCountEmployeesNotAssigned(this.organisationId, 'body').subscribe((count: number) => {
        if (count > 0) {
          this.notificationsAlert.push(this.notificationMapper.countEmployeesNotAssignedToNotification(count));
        }
      });
    }
  }
}
