import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { LoaderService } from 'app/core/services/loader.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { RouteHelper } from 'app/shared/helpers/route.helper';
import { RouteData } from 'app/app.route.model';

const SIDENAV_CONFIG = {
  hasBackdrop: false,
  mode: 'over' as MatDrawerMode,
};

@Component({
  selector: 'sywa-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  sidenavConfig = SIDENAV_CONFIG;
  hideHeader = false;
  hideSidenav = false;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private navigationService: NavigationService,
    public loaderService: LoaderService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.navigationService.init().subscribe();
    const targetData = RouteHelper.getTargetChild(this.route.snapshot).data as RouteData;
    this.hideHeader = targetData.hideHeader === true;
    this.hideSidenav = targetData.hideSidenav === true;
  }

  async toggleMenu(): Promise<void> {
    await this.sidenav.toggle();
  }

  async closeMenu(): Promise<void> {
    await this.sidenav.close();
  }
}
