import { HttpClient, HttpEvent } from '@angular/common/http';
import { Configuration } from 'app/shared/api';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_V2_PATH } from 'app/shared/api-overrides/apiV2/variables';
import { Observable } from 'rxjs';
import { Apiv2Service } from 'app/shared/api-overrides/apiV2/apiv2.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentVersionFilesV2Service extends Apiv2Service {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(API_V2_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }

  public getDocumentVersionFiles(
    url: string,
    options?: { httpHeaderAccept?: 'application/binary' | 'application/json' }
  ): Observable<HttpEvent<Blob>> {
    if (url === null) {
      throw new Error('Required parameter (url) is null or undefined when calling getDocumentVersionFiles.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options?.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      const httpHeaderAccepts: string[] = ['application/binary', 'application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get(url, {
      responseType: 'blob',
      headers,
      observe: 'response',
    });
  }
}
