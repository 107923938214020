import { Injectable } from '@angular/core';
import { UsersService as UsersServiceApi, UserVm } from 'app/shared/api';
import { map, mapTo, Observable, of } from 'rxjs';
import { UserMapper } from '../mappers/user.mapper';
import { AuthRepository } from '../state/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private usersServiceApi: UsersServiceApi,
    private userMapper: UserMapper,
    private authRepository: AuthRepository
  ) {}

  retrieveCurrentUser(): Observable<void> {
    if (this.authRepository.getCurrentUserSnapshot()) {
      return of(void 0);
    }

    return this.usersServiceApi.getCurrentUser('body').pipe(
      map((user: UserVm) => {
        const currentUser = this.userMapper.toModel(user);
        this.authRepository.updateUser(currentUser);
      }),
      mapTo<void>(void 0)
    );
  }
}
