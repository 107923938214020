import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SEARCH_INPUT_DEBOUNCE_TIME } from 'app/app.constants';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';
import { ProjectListOptionsVm, ProjectPaginateVm, ProjectsService, ProjectSummaryVm } from 'app/shared/api';
import { defaultMaxLenghtInputText, defaultMinLenghtInputText } from 'app/shared/models/form-validation.constants';
import { debounceTime, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

const DEFAULT_PROJECT_SELECT_CONF: ProjectListOptionsVm = {
  size: 10,
  sort: 'project.name',
  sortOrder: 'ASC',
};

type projectName = string;

@Component({
  selector: 'sywa-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProjectSelectComponent), multi: true }],
})
export class ProjectSelectComponent
  extends AbstractControlValueAccessor<ProjectSummaryVm>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() labelKey: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() withPreSelect = false;

  defaultMinLenghtInputText = defaultMinLenghtInputText;
  defaultMaxLenghtInputText = defaultMaxLenghtInputText;

  projects: ProjectSummaryVm[] = [];
  projectSearchControl = new FormControl();

  currentPage = 1;
  pageInfos: ProjectPaginateVm;
  hasBeenPreselected = false;

  private destroyed$: Subject<void> = new Subject();

  constructor(private projectsService: ProjectsService) {
    super();
  }

  ngOnInit(): void {
    this.onProjectSearchControlValueChanges()
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(SEARCH_INPUT_DEBOUNCE_TIME),
        tap(() => (this.currentPage = 1)),
        switchMap(() =>
          (this.getProjectSearchControlValue() as ProjectSummaryVm).id
            ? of(void 0).pipe(
                tap(() => {
                  this.projects = [this.getProjectSearchControlValue() as ProjectSummaryVm];
                  this.pageInfos = undefined;
                })
              )
            : this.retrieveProjects(this.currentPage).pipe(
                tap((projects: ProjectSummaryVm[]) => (this.projects = projects))
              )
        )
      )
      .subscribe(() => {
        if (this.withPreSelect && this.projects && this.projects.length && !this.hasBeenPreselected) {
          this.hasBeenPreselected = true;
          this.value = this.projects[0];
          this.projectSearchControl.setValue(this.projects[0]);
          return;
        }

        if (!this.getProjectSearchControlValue()) {
          this.value = undefined;
          return;
        }

        const hasAnOrganisationBeenSelected = typeof this.getProjectSearchControlValue() !== 'string';
        if (hasAnOrganisationBeenSelected) {
          this.value = this.getProjectSearchControlValue() as ProjectSummaryVm;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      changes.disabled.currentValue ? this.projectSearchControl.disable() : this.projectSearchControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onProjectSearchControlValueChanges(): Observable<ProjectSummaryVm | projectName> {
    return this.projectSearchControl.valueChanges as Observable<ProjectSummaryVm | projectName>;
  }

  getProjectSearchControlValue(): ProjectSummaryVm | projectName {
    return this.projectSearchControl.value as ProjectSummaryVm | projectName;
  }

  getProjectName(): string | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.getProjectSearchControlValue()
      ? typeof this.getProjectSearchControlValue() === 'string'
        ? (this.getProjectSearchControlValue() as string)
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (this.getProjectSearchControlValue() as ProjectSummaryVm).name
      : undefined;
  }

  writeValue(projectSummaryVm: ProjectSummaryVm): void {
    super.writeValue(projectSummaryVm);
    this.projectSearchControl.setValue(projectSummaryVm || '');
  }

  loadMoreProjects(): void {
    if (this.pageInfos?.hasNextPage) {
      this.currentPage++;
      this.retrieveProjects(this.currentPage).subscribe((projects: ProjectSummaryVm[]) => {
        this.projects = [...this.projects, ...projects];
      });
    }
  }

  retrieveProjects(page: number): Observable<ProjectSummaryVm[]> {
    return this.projectsService
      .getProjects(
        page,
        DEFAULT_PROJECT_SELECT_CONF.size,
        DEFAULT_PROJECT_SELECT_CONF.sort,
        DEFAULT_PROJECT_SELECT_CONF.sortOrder,
        this.getProjectName(),
        undefined,
        undefined,
        undefined,
        false,
        'body'
      )
      .pipe(
        tap((projectPaginateVm: ProjectPaginateVm) => (this.pageInfos = projectPaginateVm)),
        map((projectPaginateVm: ProjectPaginateVm) => projectPaginateVm.results)
      );
  }

  displayFn(project: ProjectSummaryVm): string {
    return project?.name ? project.name : '';
  }
}
