import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import Viewer from 'viewerjs';

const DEFAULT_CONF = {
  inline: true,
  backdrop: false,
  toolbar: false,
  navbar: false,
  button: false,
  title: false,
  toggleOnDblclick: false,
  zoomOnTouch: false,
  zoomOnWheel: false,
};

@Directive({
  selector: '[sywaImageViewer]',
})
export class ImageViewerDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() zoomLevel: number;
  viewerInstance: Viewer;

  constructor(private elementRef: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.viewerInstance &&
      changes.zoomLevel &&
      changes.zoomLevel.previousValue !== changes.zoomLevel.currentValue
    ) {
      this.viewerInstance.zoom(changes.zoomLevel.currentValue - changes.zoomLevel.previousValue);
    }
  }

  public ngAfterViewInit(): void {
    this.viewerInstance = new Viewer(this.elementRef.nativeElement as HTMLElement, DEFAULT_CONF);
  }

  public ngOnDestroy(): void {
    if (this.viewerInstance) {
      this.viewerInstance.destroy();
    }
  }

  public refresh(): void {
    this.viewerInstance.destroy();
    this.viewerInstance = new Viewer(this.elementRef.nativeElement as HTMLElement, DEFAULT_CONF);
  }
}
