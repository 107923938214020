import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoleSelectComponent } from './user-role-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatSelectModule];
@NgModule({
  declarations: [UserRoleSelectComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ...MATERIAL_MODULES, CheckConnectedUserHasRightModule],
  exports: [UserRoleSelectComponent],
})
export class UserRoleSelectModule {}
