import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NotificationDocumentStatusContentComponent } from './notification-document-status-content.component';

@NgModule({
  declarations: [NotificationDocumentStatusContentComponent],
  imports: [CommonModule, TranslateModule, RouterModule],
  exports: [NotificationDocumentStatusContentComponent],
})
export class NotificationDocumentStatusContentModule {}
