import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TileConfig, TileImageForm } from './tile.model';

@Component({
  selector: 'sywa-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent implements OnChanges {
  @Input() config: TileConfig;

  TileImageForm = TileImageForm;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.config &&
      changes.config.currentValue !== changes.config.previousValue &&
      this.config.imageForm === undefined
    ) {
      this.config.imageForm = TileImageForm.SQUARE;
    }
  }
}
