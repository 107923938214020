import { NgModule } from '@angular/core';
import { FullnamePipe } from './fullname.pipe';

@NgModule({
  declarations: [FullnamePipe],
  exports: [FullnamePipe],
})
/**
 * This module is used to import and configure common modules for all project
 */
export class FullnamePipeModule {}
