import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconLabelsComponent } from './icon-labels.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [IconLabelsComponent],
  imports: [CommonModule, MatIconModule, TranslateModule],
  exports: [IconLabelsComponent],
})
export class IconLabelsModule {}
