import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ROUTES } from 'app/core/routes.constants';
import { NotificationVm } from 'app/shared/api';

@Component({
  selector: 'sywa-notification-document-expiration-content',
  templateUrl: './notification-document-expiration-content.component.html',
  styleUrls: ['./notification-document-expiration-content.component.scss'],
})
export class NotificationDocumentExpirationContentComponent {
  @Input() notification: NotificationVm;

  Routes = ROUTES;

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
