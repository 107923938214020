export const defaultMinLenghtInputText = 0;
export const defaultMaxLenghtInputText = 255;
export const defaultMinInputNumber = 0;
export const defaultLengthSIRET = 14;
export const defaultSIRETRegex = '^\\d{1}\\d{12}\\d{1}$';
export const defaultIntegerRegex = '^[+-]?[0-9]*$';
export const defaultFloatRegex = '^[+-]?([0-9]*[.|,])?[0-9]+$';
export const defaultStringNameRegex = '^[a-zA-ZÀ-ÿ- ]*$';
export const defaultPasswordRegex =
  '^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]).{8,}$';
export const defaultEmailRegex = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,5})+$';
