import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NotificationInvitationComponent } from './notification-invitation.component';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';
import { IsSameAsConnectedUserOrganisationPipeModule } from 'app/shared/pipes/is-same-as-connected-user-organisation-pipe/is-same-as-connected-user-organisation-pipe.module';
import { NotificationInvitationContentModule } from '../notification-invitation-content/notification-invitation-content.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [NotificationInvitationComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    CheckConnectedUserHasRightModule,
    IsSameAsConnectedUserOrganisationPipeModule,
    NotificationInvitationContentModule,
    MatTooltipModule,
  ],
  exports: [NotificationInvitationComponent],
})
export class NotificationInvitationModule {}
