import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { RightName } from 'app/core/rights/right-name.model';
import { RightsService } from 'app/core/rights/rights.service';
import { ViewRightName } from 'app/core/rights/view-right-name.model';

@Directive({
  selector: '[sywaIfConnectedUserHasRight]',
})
export class IfConnectedUserHasRightDirective implements OnInit {
  @Input('sywaIfConnectedUserHasRight') public rightName: RightName | ViewRightName;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<object>,
    private rightsService: RightsService
  ) {}

  public ngOnInit(): void {
    this.viewContainerRef.clear();
    if (this.rightsService.connectedUserHasRight(this.rightName)) {
      // appends the ref element to DOM
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
