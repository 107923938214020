import { Component, EventEmitter, Input, Output } from '@angular/core';

const INFINITE_SCROLL_CONFIG = {
  infiniteScrollDistance: 1,
  infiniteScrollThrottle: 50,
  scrollWindow: false,
};

@Component({
  selector: 'sywa-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent {
  @Output() scrollBottomReached = new EventEmitter<void>();
  @Output() loadMoreData = new EventEmitter<void>();

  @Input() disableButton = false;
  @Input() showButton = true;

  infiniteScroll = INFINITE_SCROLL_CONFIG;

  onScroll(): void {
    this.scrollBottomReached.emit();
  }

  loadMore(): void {
    this.loadMoreData.emit();
  }
}
