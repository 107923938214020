import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuComponent } from './components/menu/menu.component';
import { PageContainerModule } from 'app/shared/components/organisms/page-container/page-container.module';
import { AddEditProjectDialogModule } from 'app/features/project/sub-modules/add-edit-project-dialog/add-edit-project-dialog.module';
import { AddEditOrganisationDialogModule } from 'app/features/organisation/sub-modules/add-edit-organisation-dialog/add-edit-organisation-dialog.module';
import { RouterModule } from '@angular/router';
import { ConfirmDialogModule } from 'app/shared/components/molecules/confirm-dialog/confirm-dialog.module';
import { InviteOrganisationDialogModule } from 'app/features/organisation/sub-modules/invite-organisation-dialog/invite-organisation-dialog.module';
import { MatDividerModule } from '@angular/material/divider';
import { RetrieveImagePipeModule } from 'app/shared/pipes/retrieve-image-pipe.module';
import { AddEditUserDialogModule } from 'app/features/user/sub-modules/add-edit-user-dialog/add-edit-user-dialog.module';
import { NgEventBus } from 'ng-event-bus';
import { ForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { IfConnectedUserHasRightModule } from 'app/shared/directives/if-connected-user-has-right/if-connected-user-has-rights.module';
import { IfConnectedUserHasSomeRightsModule } from 'app/shared/directives/if-connected-user-has-some-rights/if-connected-user-has-some-rights.module';
import { AddEditResourceDialogModule } from 'app/features/resource/sub-modules/add-edit-resource-dialog/add-edit-resource-dialog.module';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationSnackbarModule } from 'app/features/notification/sub-modules/notification-snackbar/notification-snackbar.module';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationInvitationModule } from 'app/features/notification/sub-modules/notification-invitation/notification-invitation.module';
import { NotificationAlertMessageModule } from 'app/features/notification/sub-modules/notification-alert-message/notification-alert-message.module';
import { InfoDialogModule } from 'app/shared/components/molecules/info-dialog/info-dialog.module';
import { ReportResourceDialogModule } from 'app/features/resource/sub-modules/report-resource-dialog/report-resource-dialog.module';

const MATERIAL_MODULES = [
  MatDialogModule,
  MatToolbarModule,
  MatButtonModule,
  MatSnackBarModule,
  MatMenuModule,
  MatIconModule,
  MatSidenavModule,
  MatDividerModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
];

const DIALOG_MODULES = [
  InviteOrganisationDialogModule,
  AddEditResourceDialogModule,
  AddEditProjectDialogModule,
  AddEditOrganisationDialogModule,
  ConfirmDialogModule,
  InfoDialogModule,
  AddEditUserDialogModule,
  ReportResourceDialogModule,
];

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule,
    CommonModule,
    PageContainerModule,
    RouterModule,
    ...MATERIAL_MODULES,
    ...DIALOG_MODULES,
    RetrieveImagePipeModule,
    IfConnectedUserHasRightModule,
    IfConnectedUserHasSomeRightsModule,
    CheckConnectedUserHasRightModule,
    NotificationSnackbarModule,
    AddResponsiveClassModule,
    NotificationInvitationModule,
    NotificationAlertMessageModule,
  ],
  declarations: [
    HeaderComponent,
    LayoutComponent,
    NotFoundComponent,
    MenuComponent,
    ForbiddenPageComponent,
    LoaderComponent,
  ],
  exports: [HeaderComponent, LayoutComponent],
  providers: [NgEventBus as Provider],
})
export class CoreModule {
  /**
   * Declare only services that need to be singleton
   * Only one instance of these services will be shared among lazy loaded modules
   */
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
