import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertYearToDateFromNowPipe } from './convert-year-to-date-from-now.pipe';

@NgModule({
  declarations: [ConvertYearToDateFromNowPipe],
  imports: [CommonModule],
  exports: [ConvertYearToDateFromNowPipe],
})
export class ConvertYearToDateFromNowModule {}
