export * from './accessControls.service';
import { AccessControlsService } from './accessControls.service';
export * from './benchmark.service';
import { BenchmarkService } from './benchmark.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './invitations.service';
import { InvitationsService } from './invitations.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './organisations.service';
import { OrganisationsService } from './organisations.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './projectsSiteControls.service';
import { ProjectsSiteControlsService } from './projectsSiteControls.service';
export * from './resources.service';
import { ResourcesService } from './resources.service';
export * from './suppliers.service';
import { SuppliersService } from './suppliers.service';
export * from './userPreferences.service';
import { UserPreferencesService } from './userPreferences.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [
  AccessControlsService,
  BenchmarkService,
  DefaultService,
  DocumentsService,
  InvitationsService,
  NotificationsService,
  OrganisationsService,
  ProjectsService,
  ProjectsSiteControlsService,
  ResourcesService,
  SuppliersService,
  UserPreferencesService,
  UsersService,
];
