import { Component, EventEmitter, Output } from '@angular/core';
import { FileViewerConfig } from '../components/organisms/file-viewer/file-viewer.model';
import { DocumentFileTypesMapper } from '../mappers/document-file-types.mapper';

@Component({ template: '' })
export class AbstractEditComponent {
  @Output() canceled = new EventEmitter<void>();

  fileViewerConfigs: FileViewerConfig[] = [];

  constructor(private documentFileTypesMapper: DocumentFileTypesMapper) {}

  updateFileViewerConfigs(files: File[]): void {
    this.fileViewerConfigs = files.map((file: File) => ({
      blob: file,
      fileType: this.documentFileTypesMapper.toModel(file.type),
    }));
  }

  cancel(): void {
    this.canceled.emit();
  }
}
