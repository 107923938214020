import { IconLabelsConfig } from '../../atoms/icon-labels/icon-labels.model';

export interface TileConfig {
  srcImg: string;
  srcFallbackImg?: string;
  imageForm?: TileImageForm;
  titleKey: string;
  titleTranslateParams?: { [key: string]: string };
  subTitleKey?: string;
  prohibitTranslationTitleKey?: boolean;
  prohibitTranslationSubTitleKey?: boolean;
  infos: IconLabelsConfig[];
  mainLink?: string;
}

export enum TileImageForm {
  ROUND,
  SQUARE,
}
