import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { LoaderService } from 'app/core/services/loader.service';
import { NotificationService } from 'app/core/services/notification.service';
import { employerRankMax, employerRankMin } from 'app/features/organisation/models/organisation.constants';
import {
  ErrorVm,
  OrganisationVm,
  ProjectReportingCreateRequestVm,
  ProjectsSiteControlsService,
  ProjectSummaryVm,
} from 'app/shared/api';
import { defaultMaxLenghtInputText, defaultStringNameRegex } from 'app/shared/models/form-validation.constants';
import { finalize } from 'rxjs';
import { maxAge, minAge } from '../../models/resource.constants';

@Component({
  selector: 'sywa-report-resource-form',
  templateUrl: './report-resource-form.component.html',
  styleUrls: ['./report-resource-form.component.scss'],
})
export class ReportResourceFormComponent {
  @Output() submitSucceeded = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  report: ProjectReportingCreateRequestVm = {} as ProjectReportingCreateRequestVm;
  selectedProject: ProjectSummaryVm;
  selectedEmployer: OrganisationVm;
  selectedClient: OrganisationVm;
  unknownEmployer = false;
  unknownClient = false;

  defaultMaxLenghtInputText = defaultMaxLenghtInputText;
  defaultStringNameRegex = defaultStringNameRegex;
  minAge = minAge;
  maxAge = maxAge;
  employerRankMin = employerRankMin;
  employerRankMax = employerRankMax;

  isSubmitting = false;

  constructor(
    private projectsSiteControlsService: ProjectsSiteControlsService,
    private loaderService: LoaderService,
    private notificationService: NotificationService
  ) {}

  submitReport(): void {
    this.loaderService.open();

    this.isSubmitting = true;
    this.report.projectId = this.selectedProject.id.toString();
    this.report.employerId = this.selectedEmployer?.id.toString();
    this.report.clientId = this.selectedClient?.id.toString();

    this.projectsSiteControlsService
      .addSiteReporting(this.report, 'body')
      .pipe(
        finalize(() => {
          this.loaderService.close();
          this.isSubmitting = false;
        })
      )
      .subscribe({
        next: () => {
          this.submitSucceeded.emit();
          this.notificationService.notifySuccess();
        },
        error: (error: HttpErrorResponse) => {
          const errorVm: ErrorVm = error.error as ErrorVm;
          this.notificationService.notifyError(errorVm.message);
        },
      });
  }

  cancel(): void {
    this.canceled.emit();
  }
}
