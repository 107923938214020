import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditProjectComponent } from './add-edit-project.component';
import { ProjectFormModule } from '../project-form/project-form.module';
import { FilesViewerModule } from 'app/shared/components/organisms/files-viewer/files-viewer.module';
import { TwoPanelLayoutModule } from 'app/shared/components/organisms/two-panel-layout/two-panel-layout.module';

@NgModule({
  declarations: [AddEditProjectComponent],
  imports: [CommonModule, ProjectFormModule, FilesViewerModule, TwoPanelLayoutModule],
  exports: [AddEditProjectComponent],
})
export class AddEditProjectModule {}
