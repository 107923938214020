import { Pipe, PipeTransform } from '@angular/core';
import { RightName } from 'app/core/rights/right-name.model';
import { RightsService } from 'app/core/rights/rights.service';
import { ViewRightName } from 'app/core/rights/view-right-name.model';

@Pipe({
  name: 'checkConnectedUserHasRight',
})
export class CheckConnectedUserHasRightPipe implements PipeTransform {
  constructor(private rightsService: RightsService) {}

  transform(rightName: RightName | ViewRightName, aclCondition?: boolean): boolean {
    return aclCondition !== undefined
      ? this.rightsService.connectedUserHasRight(rightName) && aclCondition
      : this.rightsService.connectedUserHasRight(rightName);
  }
}
