import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReasonDialogData } from './reason-dialog.model';

@Component({
  selector: 'sywa-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss'],
})
export class ReasonDialogComponent {
  title: string;
  message: string;
  reason: string;

  constructor(
    public dialogRef: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReasonDialogData
  ) {}

  submitReason(): void {
    this.dialogRef.close({ reason: this.reason });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
