import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteOrganisationDialogComponent } from './invite-organisation-dialog.component';
import { InviteOrganisationFormModule } from '../invite-organisation-form/invite-organisation-form.module';
import { PopupContainerModule } from 'app/shared/components/organisms/popup-container/popup-container.module';
import { TwoPanelLayoutModule } from 'app/shared/components/organisms/two-panel-layout/two-panel-layout.module';
import { AddEditOrganisationDialogModule } from '../add-edit-organisation-dialog/add-edit-organisation-dialog.module';

@NgModule({
  declarations: [InviteOrganisationDialogComponent],
  imports: [
    CommonModule,
    InviteOrganisationFormModule,
    PopupContainerModule,
    TwoPanelLayoutModule,
    AddEditOrganisationDialogModule,
  ],
  exports: [InviteOrganisationDialogComponent],
})
export class InviteOrganisationDialogModule {}
