import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditOrganisationDialogComponent } from './add-edit-organisation-dialog.component';
import { AddEditOrganisationModule } from '../add-edit-organisation/add-edit-organisation.module';
import { PopupContainerModule } from 'app/shared/components/organisms/popup-container/popup-container.module';

@NgModule({
  declarations: [AddEditOrganisationDialogComponent],
  imports: [CommonModule, PopupContainerModule, AddEditOrganisationModule],
  exports: [AddEditOrganisationDialogComponent],
})
export class AddEditOrganisationDialogModule {}
