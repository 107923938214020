import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ROUTES } from 'app/core/routes.constants';
import { NotificationVm, SupplierInvitationVm } from 'app/shared/api';

@Component({
  selector: 'sywa-notification-invitation-content',
  templateUrl: './notification-invitation-content.component.html',
  styleUrls: ['./notification-invitation-content.component.scss'],
})
export class NotificationInvitationContentComponent {
  @Input() notification: NotificationVm;

  Routes = ROUTES;
  NotificationAction = NotificationVm.ActionEnum;
  InvitationStatus = SupplierInvitationVm.StatusEnum;

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
