import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { DropzoneModule } from 'app/shared/components/organisms/dropzone/dropzone.module';
import { SelectCountryModule } from 'app/shared/components/molecules/select-country/select-country.module';
import { environment } from 'src/environments/environment';
import { DateInputModule } from 'app/shared/components/molecules/date-input/date-input.module';
import { ResourceFormComponent } from './resource-form.component';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';
import { ConvertYearToDateFromNowModule } from 'app/shared/pipes/convert-year-to-date-from-now/convert-year-to-date-from-now.module';
import { CompanyIdValidatorModule } from 'app/shared/validators/company-id/company-id-validator.module';

const MATERIAL_MODULES = [MatFormFieldModule, MatInputModule, MatDatepickerModule, MatSelectModule];

@NgModule({
  declarations: [ResourceFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    DropzoneModule,
    SelectCountryModule.forRoot(environment.defaultLanguage),
    DateInputModule,
    AddResponsiveClassModule,
    ConvertYearToDateFromNowModule,
    CompanyIdValidatorModule,
    ...MATERIAL_MODULES,
  ],
  exports: [ResourceFormComponent],
})
export class ResourceFormModule {}
