import { Injectable } from '@angular/core';
import { ResourceCreateRequestVm, ResourceUpdateRequestVm, ResourceVm } from 'app/shared/api';
import { ResourceForm } from './resource-form.model';
import { ResourceUnitView } from 'app/shared/api-overrides/apiV2/entities/Resource';

@Injectable({
  providedIn: 'root',
})
export class ResourceFormMapper {
  fromResourceVm(resource: ResourceVm | ResourceUnitView): ResourceForm {
    if (resource) {
      return {
        birthdate: resource.birthdate as string,
        employerId: resource.employer?.id,
        firstName: resource.firstName,
        lastName: resource.lastName,
        nationality: resource.nationality,
        type: resource.type,
        typeOfEmployment: resource.typeOfEmployment,
        nationalIdTwc: resource.nationalIdTwc,
      };
    }
  }

  toResourceCreateRequestVm(resource: ResourceForm): ResourceCreateRequestVm {
    if (resource) {
      return {
        birthdate: resource.birthdate,
        employerId: resource.employerId,
        firstName: this.formatToName(resource.firstName),
        lastName: this.formatToName(resource.lastName),
        nationality: resource.nationality,
        type: resource.type,
        typeOfEmployment: resource.typeOfEmployment,
        nationalIdTwc: resource.nationalIdTwc,
      };
    }
  }

  toResourceUpdateRequestVm(resource: ResourceForm): ResourceUpdateRequestVm {
    if (resource) {
      return {
        birthdate: resource.birthdate,
        firstName: this.formatToName(resource.firstName),
        lastName: this.formatToName(resource.lastName),
        nationality: resource.nationality,
        typeOfEmployment: resource.typeOfEmployment,
        nationalIdTwc: resource.nationalIdTwc,
      };
    }
  }

  private formatToName(name: string): string {
    const nameWithoutAdditionnalSpaces = name.trim().replace(/  +/g, ' ');
    return nameWithoutAdditionnalSpaces[0].toUpperCase() + nameWithoutAdditionnalSpaces.slice(1);
  }
}
