import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.route.model';
import { ForbiddenPageComponent } from './core/components/forbidden-page/forbidden-page.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ROUTES } from './core/routes.constants';
import { AttendeeModule } from './features/attendee/attendee.module';
import { NotificationModule } from './features/notification/notification.module';
import { OrganisationModule } from './features/organisation/organisation.module';
import { ProjectModule } from './features/project/project.module';
import { ResourceModule } from './features/resource/resource.module';
import { SupplierModule } from './features/supplier/supplier.module';
import { UserModule } from './features/user/user.module';
import { NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ControlModule } from './features/control/control.module';
import { CanNavigateGuard } from './core/guards/can-navigate.guard';

const routes: AppRoutes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: LayoutComponent,
        children: [
          {
            path: ROUTES.employees,
            loadChildren: (): Promise<ResourceModule> =>
              import('./features/resource/resource.module').then((m) => m.ResourceModule),
          },
          {
            path: ROUTES.attendees,
            loadChildren: (): Promise<AttendeeModule> =>
              import('./features/attendee/attendee.module').then((m) => m.AttendeeModule),
          },
          {
            path: ROUTES.organisations,
            loadChildren: (): Promise<OrganisationModule> =>
              import('./features/organisation/organisation.module').then((m) => m.OrganisationModule),
          },
          {
            path: ROUTES.suppliers,
            loadChildren: (): Promise<SupplierModule> =>
              import('./features/supplier/supplier.module').then((m) => m.SupplierModule),
          },
          {
            path: ROUTES.operations,
            loadChildren: (): Promise<ProjectModule> =>
              import('./features/project/project.module').then((m) => m.ProjectModule),
            canActivate: [CanNavigateGuard],
          },
          {
            path: ROUTES.users,
            loadChildren: (): Promise<UserModule> => import('./features/user/user.module').then((m) => m.UserModule),
          },
          {
            path: ROUTES.notifications,
            loadChildren: (): Promise<NotificationModule> =>
              import('./features/notification/notification.module').then((m) => m.NotificationModule),
          },
          {
            path: ROUTES.controls,
            loadChildren: (): Promise<ControlModule> =>
              import('./features/control/control.module').then((m) => m.ControlModule),
          },
          {
            path: ROUTES.forbidden,
            component: ForbiddenPageComponent,
          },
          {
            path: '',
            redirectTo: `${ROUTES.operations}`,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  { path: ROUTES.notfound, component: NotFoundComponent },
  { path: '**', redirectTo: ROUTES.notfound },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgxGoogleAnalyticsRouterModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
