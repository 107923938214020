import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditUserDialogComponent } from './add-edit-user-dialog.component';
import { AddEditUserModule } from '../add-edit-user/add-edit-user.module';
import { PopupContainerModule } from 'app/shared/components/organisms/popup-container/popup-container.module';

@NgModule({
  declarations: [AddEditUserDialogComponent],
  imports: [CommonModule, AddEditUserModule, PopupContainerModule],
  exports: [AddEditUserDialogComponent],
})
export class AddEditUserDialogModule {}
