import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter, map, mapTo, Observable, of, switchMap, tap } from 'rxjs';
import { Navigation } from '../models/navigation';
import { NAV_CONFIGS } from '../navigation.constants';
import { NavigationRepository } from '../state/navigation.repository';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationRepository: NavigationRepository
  ) {}

  init(): Observable<void> {
    return of(this.navigationRepository.updateNavConfigs(NAV_CONFIGS)).pipe(
      switchMap(() => this.navigationRepository.getNavConfigs()),
      tap((navConfigs: Navigation[]) => this.configureCurrentNavigation(navConfigs)),
      switchMap((navConfigs: Navigation[]) => this.configureCurrentNavigationListener(navConfigs))
    );
  }

  async navigateWithFilters<T>(route: string, filters: T): Promise<void> {
    await this.router.navigate([route], {
      queryParams: {
        ...filters,
      },
      queryParamsHandling: 'merge',
    });
  }

  getFiltersFromUrl<T>(): Observable<T> {
    return this.route.queryParams.pipe(map((params: Params) => params as T));
  }

  private configureCurrentNavigation(navConfigs: Navigation[]): void {
    const currentNavigation = navConfigs.find((navConfig: Navigation) => this.router.url.includes(navConfig.route));
    this.navigationRepository.updateCurrentNavigation(currentNavigation);
  }

  private configureCurrentNavigationListener(navConfigs: Navigation[]): Observable<void> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) =>
        navConfigs.find((navConfig: Navigation) => event.urlAfterRedirects.includes(navConfig.route))
      ),
      filter(
        (navigation: Navigation) => this.navigationRepository.getCurrentNavigationSnapshot()?.id !== navigation?.id
      ),
      tap((navigation: Navigation) => this.navigationRepository.updateCurrentNavigation(navigation)),
      mapTo<void>(void 0)
    );
  }
}
