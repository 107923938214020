export interface IconLabelsConfig {
  iconName?: string;
  labelsKeys: IconLabelsKeys[];
  state?: IconLabelsState;
}

export interface IconLabelsKeys {
  key: string;
  params?: { [key: string]: string | number };
  prohibitTranslation?: boolean;
  state?: IconLabelsState;
  additionnalClasses?: string[];
}

export enum IconLabelsState {
  DEFAULT,
  OK,
  KO,
  IN_PROGRESS,
}
