import { Injectable } from '@angular/core';
import { DocumentFileTypes } from '../models/document-file-types';
import { MimeTypes } from '../models/mime-types';

@Injectable({
  providedIn: 'root',
})
export class DocumentFileTypesMapper {
  toModel(contentType: string): DocumentFileTypes {
    switch (contentType) {
      case MimeTypes.PDF:
        return DocumentFileTypes.PDF;

      case MimeTypes.PNG:
      case MimeTypes.SVG:
      case MimeTypes.JPG:
        return DocumentFileTypes.IMG;
    }

    return undefined;
  }

  toString(contentType: string): string {
    switch (contentType) {
      case MimeTypes.PDF:
        return 'pdf';
      case MimeTypes.PNG:
        return 'png';
      case MimeTypes.SVG:
        return 'svg';
      case MimeTypes.JPG:
        return 'jpg';
    }

    return '';
  }
}
