import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ROUTES } from 'app/core/routes.constants';
import { DialogService } from 'app/core/services/dialog.service';
import { AddEditOrganisationDialogComponent } from '../add-edit-organisation-dialog/add-edit-organisation-dialog.component';
import { AddEditOrganisationDialogResult } from '../add-edit-organisation-dialog/add-edit-organisation-dialog.model';
import { InviteOrganisationDialogResult } from './invite-organisation-dialog.model';
import { RightsService } from 'app/core/rights/rights.service';
import { ViewRightName } from 'app/core/rights/view-right-name.model';

@Component({
  selector: 'sywa-invite-organisation-dialog',
  templateUrl: './invite-organisation-dialog.component.html',
  styleUrls: ['./invite-organisation-dialog.component.scss'],
})
export class InviteOrganisationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<InviteOrganisationDialogComponent>,
    private dialogService: DialogService,
    private router: Router,
    private rightsService: RightsService
  ) {}

  close(invitationSent?: boolean): void {
    const result: InviteOrganisationDialogResult = { invitationSent };
    this.dialogRef.close(result);
  }

  openAddOrganisationPopup(): void {
    this.dialogService
      .open<AddEditOrganisationDialogComponent, undefined, AddEditOrganisationDialogResult>(
        AddEditOrganisationDialogComponent
      )
      .subscribe((result: AddEditOrganisationDialogResult) => {
        // @see sywa-2113 no redirection for Manager, they can't see the organisation page
        if (result?.organisationId && this.rightsService.connectedUserHasRight(ViewRightName.VIEW_ANY_ORGANISATION)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate([`${ROUTES.organisations}/${result.organisationId}`]);
        }
      });

    this.dialogRef.close();
  }
}
