import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.identityUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,

  redirectUriAsPostLogoutRedirectUriFallback: true,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: environment.identityClientId,

  revocationEndpoint: `${environment.identityUrl}/protocol/openid-connect/revoke`,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid profile email',

  showDebugInformation: true,

  sessionChecksEnabled: false,

  // timeoutFactor: 0.01,
  preserveRequestedRoute: true,
};
