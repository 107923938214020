import { Injectable, Optional } from '@angular/core';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: OAuthResourceServerErrorHandler,
    private oauthService: OAuthService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {}

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    const url = req.url.toLowerCase();

    if (!this.checkUrl(url)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpResponse<object>) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          return this.handle401ErrorDisconnect(req, next);
        }
        return this.errorHandler.handleError(err);
      })
    ) as Observable<HttpEvent<object>>;
  }

  private async handle401ErrorDisconnect(
    request: HttpRequest<object>,
    next: HttpHandler
  ): Promise<Observable<HttpEvent<object>>> {
    await this.oauthService.revokeTokenAndLogout();
    await this.oauthService.loadDiscoveryDocumentAndLogin();
    await this.oauthService.tryLogin();

    return next.handle(request);
  }

  private checkUrl(url: string): boolean {
    return this.moduleConfig.resourceServer.allowedUrls.some((u) => url.startsWith(u));
  }
}
