import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { RightName } from 'app/core/rights/right-name.model';
import { RightsService } from 'app/core/rights/rights.service';
import { ROUTES } from 'app/core/routes.constants';
import { UserService } from 'app/core/services/user.service';
import { map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanNavigateGuard implements CanActivate {
  constructor(private router: Router, private rightsService: RightsService, private userService: UserService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userService.retrieveCurrentUser().pipe(
      switchMap(() => of(this.rightsService.connectedUserHasRight(RightName.CAN_NAVIGATE))),
      map((canNavigate: boolean) =>
        canNavigate ? true : this.router.createUrlTree([`${ROUTES.users}/${ROUTES.profile}`])
      )
    );
  }
}
