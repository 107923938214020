export const ROUTES = {
  employees: 'employees',
  notfound: 'notfound',
  operations: 'operations',
  projects: 'projects',
  suppliers: 'suppliers',
  organisations: 'organisations',
  attendees: 'attendees',
  resources: 'resources',
  forbidden: 'forbidden',
  users: 'users',
  profile: 'profile',
  notifications: 'notifications',
  subscription: 'subscription',
  controls: 'controls',
  documentVersions: 'documentVersions',
  intervenants: 'intervenants',
  obligations: 'obligations',
  affectations: 'affectations',
};

export const ROUTES_IDS = {
  organisationId: 'organisationId',
  projectId: 'projectId',
  employeeId: 'employeeId',
  attendeeId: 'attendeeId',
  invitationId: 'invitationId',
};
