import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MimeTypeTranslatePipe } from './mime-type-translate.pipe';

@NgModule({
  declarations: [MimeTypeTranslatePipe],
  imports: [CommonModule],
  exports: [MimeTypeTranslatePipe],
})
export class MimeTypeTranslatePipeModule {}
