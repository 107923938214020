import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';
import { DocumentFileMapper } from 'app/shared/mappers/document-file.mapper';
import { DocumentFile } from 'app/shared/models/document-file';
import { MimeTypes } from 'app/shared/models/mime-types';
import { RejectedFile } from 'ngx-dropzone/lib/ngx-dropzone.service';
import { ACCEPTED_FILE_EXTENSIONS, ACCEPTED_FILE_SIZE_BYTES, ACCEPTED_PICTURE_EXTENSIONS } from './dropzone.constants';

export const DEFAULT_ACCEPTED_FILE_EXTENSIONS: MimeTypes[] = ACCEPTED_FILE_EXTENSIONS;
export const DEFAULT_ACCEPTED_FILE_SIZE_BYTES = ACCEPTED_FILE_SIZE_BYTES;

@Component({
  selector: 'sywa-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DropzoneComponent), multi: true }],
})
export class DropzoneComponent extends AbstractControlValueAccessor<DocumentFile[]> implements OnInit {
  @Input() labelKey: string;
  @Input() acceptedMimeTypes: MimeTypes[] = DEFAULT_ACCEPTED_FILE_EXTENSIONS;
  @Input() disabled = false;
  @Input() maxFileSize: number = DEFAULT_ACCEPTED_FILE_SIZE_BYTES;
  @Input() allowMultiple = true;
  @Output() sizeExceededError = new EventEmitter<void>();

  typeDocument = 'document';
  accept: string;

  constructor(private cd: ChangeDetectorRef, private documentFileMapper: DocumentFileMapper) {
    super();
  }

  ngOnInit(): void {
    this.accept = this.acceptedMimeTypes.join(',');

    if (!this.labelKey) {
      this.setDefaultLabel();
    }

    if (this.labelKey.includes('photo')) {
      this.typeDocument = 'user';
    } else if (this.acceptedMimeTypes.every((mimeType: MimeTypes) => ACCEPTED_PICTURE_EXTENSIONS.includes(mimeType))) {
      this.typeDocument = 'picture';
    }
  }

  addFiles(files: File[]): void {
    const documentFiles: DocumentFile[] = files.map((file: File) => this.documentFileMapper.fromFile(file));
    if (!this.value || !this.allowMultiple) {
      this.value = [...documentFiles];
    } else {
      this.value = [...this.value, ...documentFiles];
    }
  }

  rejectFiles(files: RejectedFile[]): void {
    if (files.some((rejectedFile: RejectedFile) => rejectedFile.reason === 'size')) {
      this.sizeExceededError.emit();
    }
  }

  removeFile(file: DocumentFile): void {
    this.value = [...this.value].filter((aFile) => aFile !== file);
  }

  writeValue(files: DocumentFile[]): void {
    super.writeValue(files);
    this.cd.markForCheck();
  }

  private setDefaultLabel(): void {
    this.labelKey =
      (this.acceptedMimeTypes.includes(MimeTypes.JPG) ||
        this.acceptedMimeTypes.includes(MimeTypes.PNG) ||
        this.acceptedMimeTypes.includes(MimeTypes.SVG)) &&
      !this.acceptedMimeTypes.includes(MimeTypes.PDF)
        ? 'form.dropzone.label.drop-picture'
        : 'form.dropzone.label.drop-file';
  }
}
