import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NotificationInvitationContentComponent } from './notification-invitation-content.component';

@NgModule({
  declarations: [NotificationInvitationContentComponent],
  imports: [CommonModule, TranslateModule, RouterModule],
  exports: [NotificationInvitationContentComponent],
})
export class NotificationInvitationContentModule {}
