import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sywa-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent {
  constructor(private router: Router) {}

  async navigateToDefaultPage(): Promise<void> {
    await this.router.navigate(['']);
  }
}
