import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditOrganisationComponent } from './add-edit-organisation.component';
import { TwoPanelLayoutModule } from 'app/shared/components/organisms/two-panel-layout/two-panel-layout.module';
import { FilesViewerModule } from 'app/shared/components/organisms/files-viewer/files-viewer.module';
import { OrganisationFormModule } from '../organisation-form/organisation-form.module';

@NgModule({
  declarations: [AddEditOrganisationComponent],
  imports: [CommonModule, TwoPanelLayoutModule, FilesViewerModule, OrganisationFormModule],
  exports: [AddEditOrganisationComponent],
})
export class AddEditOrganisationModule {}
