import { Injectable } from '@angular/core';
import { OrganisationCreateRequestVm, OrganisationUpdateRequestVm, OrganisationVm } from 'app/shared/api';
import { OrganisationForm } from './organisation-form';

@Injectable({
  providedIn: 'root',
})
export class OrganisationFormMapper {
  fromOrganisationVm(organisationVm: OrganisationVm): OrganisationForm {
    if (organisationVm) {
      return {
        country: organisationVm.country,
        address: organisationVm.address,
        contacts: organisationVm.contacts,
        nationalId: organisationVm.nationalId,
        name: organisationVm.name,
        btpCardRequired: organisationVm.btpCardRequired,
        temporaryEmployment: organisationVm.temporaryEmployment,
        provider: organisationVm.provider,
      };
    }
  }

  fromOrganisationCreateRequest(organisationCreateRequest: OrganisationCreateRequestVm): OrganisationForm {
    if (organisationCreateRequest) {
      return {
        country: organisationCreateRequest.country,
        address: organisationCreateRequest.address,
        contacts: organisationCreateRequest.contacts,
        nationalId: organisationCreateRequest.nationalId,
        name: organisationCreateRequest.name,
        btpCardRequired: true,
        temporaryEmployment: organisationCreateRequest.temporaryEmployment,
        provider: organisationCreateRequest.provider,
        providerId: organisationCreateRequest.providerId,
      };
    }
  }

  toOrganisationCreateRequestVm(organisationForm: OrganisationForm): OrganisationCreateRequestVm {
    if (organisationForm) {
      return {
        country: organisationForm.country,
        address: organisationForm.address,
        contacts: organisationForm.contacts,
        nationalId: organisationForm.nationalId.replace(/\s+/g, ''),
        name: organisationForm.name,
        btpCardRequired: organisationForm.btpCardRequired || false,
        temporaryEmployment: organisationForm.temporaryEmployment || false,
        provider: organisationForm.provider || OrganisationCreateRequestVm.ProviderEnum.Local,
        providerId: organisationForm.providerId?.toString() || '',
      };
    }
  }

  toOrganisationUpdateRequestVm(organisationForm: OrganisationForm): OrganisationUpdateRequestVm {
    if (organisationForm) {
      return {
        nationalId: organisationForm.nationalId,
        contacts: organisationForm.contacts,
        name: organisationForm.name,
        address: organisationForm.address,
        btpCardRequired: organisationForm.btpCardRequired,
        temporaryEmployment: organisationForm.temporaryEmployment,
        provider: organisationForm.provider || OrganisationUpdateRequestVm.ProviderEnum.Local,
        providerId: organisationForm.providerId?.toString() || undefined,
      };
    }
  }
}
