import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';

@Component({
  selector: 'sywa-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NavListComponent), multi: true }],
})
export class NavListComponent extends AbstractControlValueAccessor<number> {
  @Input() max: number;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  previous(event: Event): void {
    event.stopPropagation();
    if (this.value === 1) {
      return;
    }

    this.value = this.value - 1;
  }

  next(event: Event): void {
    event.stopPropagation();
    if (this.value === this.max) {
      return;
    }

    this.value = this.value + 1;
  }

  writeValue(position: number): void {
    super.writeValue(position);
    this.cd.markForCheck();
  }
}
