import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NotificationDocumentContentComponent } from './notification-document-content.component';

@NgModule({
  declarations: [NotificationDocumentContentComponent],
  imports: [CommonModule, TranslateModule, RouterModule],
  exports: [NotificationDocumentContentComponent],
})
export class NotificationDocumentContentModule {}
