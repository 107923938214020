import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditProjectDialogData, AddEditProjectDialogResult } from './add-edit-project-dialog.model';

@Component({
  selector: 'sywa-add-edit-project-dialog',
  templateUrl: './add-edit-project-dialog.component.html',
  styleUrls: ['./add-edit-project-dialog.component.scss'],
})
export class AddEditProjectDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public addEditProjectDialogData: AddEditProjectDialogData,
    private dialogRef: MatDialogRef<AddEditProjectDialogComponent>
  ) {}

  close(projectId?: string): void {
    const result: AddEditProjectDialogResult = { projectId };
    this.dialogRef.close(result);
  }
}
