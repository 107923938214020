import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputComponent } from './date-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';

import { MatMomentDateModule } from '@angular/material-moment-adapter';

import 'moment/locale/fr'; // TODO : https://redfroggy.atlassian.net/browse/SYWA-655

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatDatepickerModule, MatMomentDateModule];

@NgModule({
  declarations: [DateInputComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, ...MATERIAL_MODULES],
  exports: [DateInputComponent],
})
export class DateInputModule {}
