// Handle this in the backend > https://redfroggy.atlassian.net/browse/SYWA-1100

import { UserVm } from 'app/shared/api';
import { ViewRightName } from './view-right-name.model';
import { ViewRight } from './view-right.model';

export const VIEW_RIGHTS: ViewRight[] = [
  {
    name: ViewRightName.ADD_ORGANISATION_FROM_HEADER,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: ViewRightName.VIEW_ALL_INVITATIONS,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: ViewRightName.VIEW_USERS_EMAIL,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: ViewRightName.VIEW_ANY_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
];
