import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileViewerConfig } from '../file-viewer/file-viewer.model';

@Component({
  selector: 'sywa-files-viewer',
  templateUrl: './files-viewer.component.html',
  styleUrls: ['./files-viewer.component.scss'],
})
export class FilesViewerComponent implements OnChanges {
  @Input() fileViewerConfigs: FileViewerConfig[];
  @Input() displayLastByDefault = true;
  currentFilePosition = 1;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.displayLastByDefault &&
      changes.fileViewerConfigs &&
      changes.fileViewerConfigs.previousValue !== changes.fileViewerConfigs.currentValue
    ) {
      this.currentFilePosition = this.fileViewerConfigs.length;
    }
  }
}
