import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sywa-report-resource-dialog',
  templateUrl: './report-resource-dialog.component.html',
  styleUrls: ['./report-resource-dialog.component.scss'],
})
export class ReportResourceDialogComponent {
  constructor(private dialogRef: MatDialogRef<ReportResourceDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
