import { Injectable } from '@angular/core';
import { OrganisationVm, OrganisationsService, SupplierProjectVm, UserVm } from 'app/shared/api';
import { Observable, of, switchMap } from 'rxjs';
import { AuthRepository } from '../state/auth.repository';
import { RightName } from './right-name.model';
import { ViewRightName } from './view-right-name.model';

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  constructor(private authRepository: AuthRepository, private organisationsService: OrganisationsService) {}

  connectedUserHasRight(rightName: RightName | ViewRightName): boolean {
    if (RightName[rightName]) {
      return this.authRepository.getUserRights().includes(rightName as RightName);
    } else if (ViewRightName[rightName]) {
      return this.authRepository.getUserViewRights().includes(rightName as ViewRightName);
    }
  }

  connectedUserHasSomeRights(rightNames: (RightName | ViewRightName)[]): boolean {
    return rightNames.some((rightName: RightName | ViewRightName) => this.connectedUserHasRight(rightName));
  }

  canNavigateToDashboard(): Observable<boolean> {
    const currentUser = this.authRepository.getCurrentUserSnapshot();
    if (currentUser?.roles?.includes(UserVm.RolesEnum.Superadmin)) {
      return of(true);
    }

    if (
      currentUser?.roles?.includes(UserVm.RolesEnum.Manager) ||
      currentUser?.roles?.includes(UserVm.RolesEnum.ManagerOperation)
    ) {
      let organisationName: string;
      return this.authRepository.getCurrentUserOrganisation().pipe(
        switchMap((organisation: OrganisationVm) => {
          organisationName = organisation.name;
          return this.organisationsService.getProjectsForOrganisation(organisation.id, 'body');
        }),
        switchMap((projects: SupplierProjectVm[]) => {
          if (projects.filter((project) => project.clientName === organisationName).length > 0) {
            return of(true);
          } else {
            return of(false);
          }
        })
      );
    } else {
      return of(false);
    }
  }
}
