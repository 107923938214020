import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SEARCH_INPUT_DEBOUNCE_TIME } from 'app/app.constants';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';
import {
  OrganisationListOptionsVm,
  OrganisationPaginateVm,
  OrganisationsService,
  OrganisationVm,
} from 'app/shared/api';
import { CountryCode } from 'app/shared/components/molecules/select-country/select-country.constants';
import { defaultMaxLenghtInputText, defaultMinLenghtInputText } from 'app/shared/models/form-validation.constants';
import { debounceTime, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

const DEFAULT_ORGANISATION_SELECT_CONF: OrganisationListOptionsVm = {
  size: 10,
  sort: 'organisations.name',
  sortOrder: 'ASC',
};

type organisationName = string;

@Component({
  selector: 'sywa-organisation-select',
  templateUrl: './organisation-select.component.html',
  styleUrls: ['./organisation-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OrganisationSelectComponent), multi: true }],
})
export class OrganisationSelectComponent
  extends AbstractControlValueAccessor<OrganisationVm>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() labelKey: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() countryFilter: CountryCode;

  defaultMinLenghtInputText = defaultMinLenghtInputText;
  defaultMaxLenghtInputText = defaultMaxLenghtInputText;

  organisationSearchControl = new FormControl();
  organisations: OrganisationVm[] = [];

  currentPage = 1;
  pageInfos: OrganisationPaginateVm;

  private destroyed$: Subject<void> = new Subject();

  constructor(private organisationsService: OrganisationsService) {
    super();
  }

  ngOnInit(): void {
    this.onOrganisationSearchControlValueChanges()
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(SEARCH_INPUT_DEBOUNCE_TIME),
        tap(() => (this.currentPage = 1)),
        switchMap(() =>
          (this.getOrganisationSearchControlValue() as OrganisationVm).id
            ? of(void 0).pipe(
                tap(() => {
                  this.organisations = [this.getOrganisationSearchControlValue() as OrganisationVm];
                  this.pageInfos = undefined;
                })
              )
            : this.retrieveOrganisations(this.currentPage).pipe(
                tap((organisations: OrganisationVm[]) => (this.organisations = organisations))
              )
        )
      )
      .subscribe(() => {
        if (!this.getOrganisationSearchControlValue()) {
          this.value = undefined;
          return;
        }

        const hasAnOrganisationBeenSelected = typeof this.getOrganisationSearchControlValue() !== 'string';
        if (hasAnOrganisationBeenSelected) {
          this.value = this.getOrganisationSearchControlValue() as OrganisationVm;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      changes.disabled.currentValue
        ? this.organisationSearchControl.disable()
        : this.organisationSearchControl.enable();
    }

    if (changes.countryFilter && !changes.countryFilter.firstChange) {
      this.currentPage = 1;
      this.retrieveOrganisations(this.currentPage)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((organisations: OrganisationVm[]) => {
          this.organisations = organisations;
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onOrganisationSearchControlValueChanges(): Observable<OrganisationVm | organisationName> {
    return this.organisationSearchControl.valueChanges as Observable<OrganisationVm | organisationName>;
  }

  getOrganisationSearchControlValue(): OrganisationVm | organisationName {
    return this.organisationSearchControl.value as OrganisationVm | organisationName;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOrganisationName(): string | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.getOrganisationSearchControlValue()
      ? typeof this.getOrganisationSearchControlValue() === 'string'
        ? (this.getOrganisationSearchControlValue() as string)
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (this.getOrganisationSearchControlValue() as OrganisationVm).name
      : undefined;
  }

  writeValue(organisationVm: OrganisationVm): void {
    super.writeValue(organisationVm);
    this.organisationSearchControl.setValue(organisationVm || '');
  }

  loadMoreClients(): void {
    if (this.pageInfos?.hasNextPage) {
      this.currentPage++;
      this.retrieveOrganisations(this.currentPage).subscribe((organisations: OrganisationVm[]) => {
        this.organisations = [...this.organisations, ...organisations];
      });
    }
  }

  retrieveOrganisations(page: number): Observable<OrganisationVm[]> {
    return this.organisationsService
      .getOrganisations(
        page,
        DEFAULT_ORGANISATION_SELECT_CONF.size,
        DEFAULT_ORGANISATION_SELECT_CONF.sort,
        DEFAULT_ORGANISATION_SELECT_CONF.sortOrder,
        this.getOrganisationName(),
        this.countryFilter,
        undefined,
        undefined,
        'body'
      )
      .pipe(
        tap((organisationPaginateVm: OrganisationPaginateVm) => (this.pageInfos = organisationPaginateVm)),
        map((organisationPaginateVm: OrganisationPaginateVm) => organisationPaginateVm.results)
      );
  }

  displayFn(organisation: OrganisationVm): string {
    return organisation?.name ? organisation.name : '';
  }
}
