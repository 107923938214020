import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfConnectedUserHasRightDirective } from './if-connected-user-has-right.directive';

@NgModule({
  declarations: [IfConnectedUserHasRightDirective],
  imports: [CommonModule],
  exports: [IfConnectedUserHasRightDirective],
})
export class IfConnectedUserHasRightModule {}
