import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ROUTES } from 'app/core/routes.constants';
import { NotificationVm, ProjectSiteControlVm } from 'app/shared/api';

@Component({
  selector: 'sywa-notification-control-content',
  templateUrl: './notification-control-content.component.html',
  styleUrls: ['./notification-control-content.component.scss'],
})
export class NotificationControlContentComponent {
  @Input() notification: NotificationVm;

  Routes = ROUTES;
  NotificationAction = NotificationVm.ActionEnum;
  ControlEnum = ProjectSiteControlVm.ControlEnum;

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
