import { Injectable } from '@angular/core';
import { NgEventBus } from 'ng-event-bus';
import { MetaData } from 'ng-event-bus/lib/meta-data';
import { map, Observable } from 'rxjs';
import { EventsName } from './broadcast-events-name.model';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  constructor(private eventBus: NgEventBus) {}

  dispatch<T>(eventName: EventsName, data?: T): void {
    this.eventBus.cast(eventName, data);
  }

  listen<T>(eventName: EventsName): Observable<T> {
    return this.eventBus.on(eventName).pipe(map((metaData: MetaData) => metaData.data as T));
  }
}
