export const environment = {
  production: false,
  defaultLanguage: 'fr',
  sentryUrl: 'https://eeb34a2d88bd4d2f879cbf8db569f080@sentry.io/1828131',
  apiUrl: 'https://api.int.sywa.fr',
  apiV2Url: 'https://f20s4xdwfg.execute-api.eu-west-3.amazonaws.com/dev',
  identityUrl: 'https://auth.int.sywa.fr/auth/realms/sywa',
  identityClientId: 'web_app',
  ga: 'G-CODE',
  s3Bucket: 'https://s3.eu-west-3.amazonaws.com/assets.int.sywa.fr',
};
