import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectSelectComponent } from './project-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'app/shared/components/organisms/infinite-scroll/infinite-scroll.module';
import { MatInputModule } from '@angular/material/input';

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatAutocompleteModule];

@NgModule({
  declarations: [ProjectSelectComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, InfiniteScrollModule, ...MATERIAL_MODULES],
  exports: [ProjectSelectComponent],
})
export class ProjectSelectModule {}
