import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditOrganisationDialogData, AddEditOrganisationDialogResult } from './add-edit-organisation-dialog.model';

@Component({
  selector: 'sywa-add-edit-organisation-dialog',
  templateUrl: './add-edit-organisation-dialog.component.html',
  styleUrls: ['./add-edit-organisation-dialog.component.scss'],
})
export class AddEditOrganisationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public addEditOrganisationDialogData: AddEditOrganisationDialogData,
    private dialogRef: MatDialogRef<AddEditOrganisationDialogComponent>
  ) {}

  close(organisationId?: string): void {
    const result: AddEditOrganisationDialogResult = { organisationId };
    this.dialogRef.close(result);
  }
}
