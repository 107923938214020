import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCountryComponent } from './select-country.component';
import { MatSelectCountryModule, MatSelectCountryLangToken } from '@angular-material-extensions/select-country';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RequiredCountryValidatorDirective } from './required-country-validator.directive';

@NgModule({
  declarations: [SelectCountryComponent, RequiredCountryValidatorDirective],
  imports: [CommonModule, MatSelectCountryModule, FormsModule, TranslateModule],
  exports: [SelectCountryComponent, RequiredCountryValidatorDirective],
})
export class SelectCountryModule {
  static forRoot(defaulLanguage: string): ModuleWithProviders<SelectCountryModule> {
    return {
      ngModule: SelectCountryModule,
      providers: [{ provide: MatSelectCountryLangToken, useValue: defaulLanguage }],
    };
  }
}
