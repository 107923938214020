import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _openCallsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public get isOpened$(): Observable<boolean> {
    return this._openCallsCount$.asObservable().pipe(map((openCallsCount: number) => openCallsCount > 0));
  }

  open(): void {
    this._openCallsCount$.next(this._openCallsCount$.value + 1);
  }

  close(): void {
    this._openCallsCount$.next(this._openCallsCount$.value - 1);
  }
}
