import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ResponsiveService } from 'app/shared/services/responsive.service';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[sywaAddResponsiveClass]',
})
export class AddResponsiveClassDirective implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  constructor(
    private responsiveService: ResponsiveService,
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {
    this.responsiveService
      .isMobileMode()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobileMode: boolean) => {
        if (isMobileMode) {
          this.renderer.addClass(this.hostElement.nativeElement, 'mobileMode');
        } else {
          this.renderer.removeClass(this.hostElement.nativeElement, 'mobileMode');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
