import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddResponsiveClassDirective } from './add-responsive-class.directive';

@NgModule({
  declarations: [AddResponsiveClassDirective],
  imports: [CommonModule],
  exports: [AddResponsiveClassDirective],
})
export class AddResponsiveClassModule {}
