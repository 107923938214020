import { Pipe, PipeTransform } from '@angular/core';
import { MimeTypes } from 'app/shared/models/mime-types';

export const MIME_TYPE_DICTIONARY: Array<{ mimeType: MimeTypes; translation: string }> = [
  { mimeType: MimeTypes.JPG, translation: 'jpg' },
  { mimeType: MimeTypes.PDF, translation: 'pdf' },
  { mimeType: MimeTypes.PNG, translation: 'png' },
  { mimeType: MimeTypes.SVG, translation: 'svg' },
];
@Pipe({
  name: 'mimeTypeTranslate',
})
export class MimeTypeTranslatePipe implements PipeTransform {
  transform(mimeTypeToTranslate: MimeTypes): string {
    return MIME_TYPE_DICTIONARY.find(({ mimeType }) => mimeType === mimeTypeToTranslate).translation;
  }
}
