import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropzoneComponent } from './dropzone.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TranslateModule } from '@ngx-translate/core';
import { BytesPipeModule } from 'app/shared/pipes/bytes-pipe.module';
import { MimeTypeTranslatePipeModule } from 'app/shared/pipes/mime-types-list-pipe/mime-type-translate-pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';

@NgModule({
  declarations: [DropzoneComponent],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    TranslateModule,
    BytesPipeModule,
    MimeTypeTranslatePipeModule,
    MatIconModule,
    AddResponsiveClassModule,
  ],
  exports: [DropzoneComponent],
})
export class DropzoneModule {}
