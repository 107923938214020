import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'app/shared/api';
import { API_V2_PATH } from 'app/shared/api-overrides/apiV2/variables';
import { Observable } from 'rxjs';
import DocumentVersion from 'app/features/documentVersion/models/document-version';
import { Apiv2Service } from 'app/shared/api-overrides/apiV2/apiv2.service';
import { ROUTES } from 'app/core/routes.constants';

@Injectable({
  providedIn: 'root',
})
export class DocumentVersionV2Service extends Apiv2Service {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(API_V2_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }

  public getDocumentVersion(documentVersionId: string): Observable<DocumentVersion> {
    if (documentVersionId === null) {
      throw new Error(
        'Required parameter (documentVersionId) is null or undefined when calling getResourceObligations.'
      );
    }

    return this.httpGet<DocumentVersion>(
      `${this.configuration.basePath}/${ROUTES.documentVersions}/${encodeURIComponent(String(documentVersionId))}`
    );
  }

  public createDocumentVersion(body: FormData): Observable<DocumentVersion> {
    const headers = this.defaultHeaders
      .set('Authorization', `Bearer ${this.configuration.lookupCredential('bearer')}`)
      .set('Accept', 'application/json');

    return this.httpPost<DocumentVersion>(`${this.configuration.basePath}/${ROUTES.documentVersions}`, body, {
      headers,
    });
  }
}
