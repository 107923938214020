import { Inject, Injectable, InjectionToken } from '@angular/core';
import { createStore, select, Store, withProps } from '@ngneat/elf';
import { NotificationVm } from 'app/shared/api';
import { Observable } from 'rxjs';

interface NotificationProps {
  receivedNotifications: NotificationVm[];
  oldestDate: Date;
}

const notificationStoreName = 'notification';

export type NotificationStore = Store<{ name: string; state: NotificationProps; config: undefined }, NotificationProps>;
export const NotificationStoreToken = new InjectionToken<NotificationStore>('NotificationStoreType', {
  providedIn: 'root',
  factory: (): NotificationStore =>
    createStore(
      { name: notificationStoreName },
      withProps<NotificationProps>({ receivedNotifications: [], oldestDate: new Date() })
    ),
});

@Injectable({
  providedIn: 'root',
})
export class NotificationRepository {
  constructor(@Inject(NotificationStoreToken) public notificationStore: NotificationStore) {}
  getTotalReceivedNotifications(): number {
    return this.notificationStore.state.receivedNotifications.length;
  }

  getTotalReceivedNotifications$(): Observable<number> {
    return this.notificationStore.pipe(select((state) => state.receivedNotifications.length));
  }

  resetReceivedNotifications(): void {
    this.notificationStore.update((state) => ({
      ...state,
      receivedNotifications: [],
      oldestDate: new Date(),
    }));
  }
}
