import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupContainerModule } from 'app/shared/components/organisms/popup-container/popup-container.module';
import { AddEditResourceModule } from '../add-edit-resource/add-edit-resource.module';
import { AddEditResourceDialogComponent } from './add-edit-resource-dialog.component';

@NgModule({
  declarations: [AddEditResourceDialogComponent],
  imports: [CommonModule, AddEditResourceModule, PopupContainerModule],
  exports: [AddEditResourceDialogComponent],
})
export class AddEditResourceDialogModule {}
