import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteOrganisationFormComponent } from './invite-organisation-form.component';
import { EmailChipInputModule } from 'app/shared/components/molecules/email-chip-input/email-chip-input.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SelectCountryModule } from 'app/shared/components/molecules/select-country/select-country.module';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationSelectModule } from 'app/features/organisation/sub-modules/organisation-select/organisation-select.module';
import { OrganisationTileModule } from '../organisation-tile/organisation-tile.module';
import { DatetimeInputModule } from 'app/shared/components/molecules/datetime-input/datetime-input.module';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';

const MATERIAL_MODULES = [MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule];

@NgModule({
  declarations: [InviteOrganisationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SelectCountryModule.forRoot(environment.defaultLanguage),
    OrganisationSelectModule,
    OrganisationTileModule,
    EmailChipInputModule,
    DatetimeInputModule,
    AddResponsiveClassModule,
    CheckConnectedUserHasRightModule,
    ...MATERIAL_MODULES,
  ],
  exports: [InviteOrganisationFormComponent],
})
export class InviteOrganisationFormModule {}
