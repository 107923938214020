import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesViewerComponent } from './files-viewer.component';
import { FileViewerModule } from '../file-viewer/file-viewer.module';
import { NavListModule } from '../../molecules/nav-list/nav-list.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FilesViewerComponent],
  imports: [CommonModule, FileViewerModule, NavListModule, FormsModule, TranslateModule],
  exports: [FilesViewerComponent],
})
export class FilesViewerModule {}
