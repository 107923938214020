import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationSelectComponent } from './organisation-select.component';
import { InfiniteScrollModule } from 'app/shared/components/organisms/infinite-scroll/infinite-scroll.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatAutocompleteModule, MatTooltipModule];

@NgModule({
  declarations: [OrganisationSelectComponent],
  imports: [CommonModule, InfiniteScrollModule, TranslateModule, FormsModule, ReactiveFormsModule, ...MATERIAL_MODULES],
  exports: [OrganisationSelectComponent],
})
export class OrganisationSelectModule {}
