import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileComponent } from './tile.component';
import { TranslateModule } from '@ngx-translate/core';
import { RetrieveImagePipeModule } from 'app/shared/pipes/retrieve-image-pipe.module';
import { IconLabelsModule } from '../../atoms/icon-labels/icon-labels.module';
import { RouterModule } from '@angular/router';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';

@NgModule({
  declarations: [TileComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RetrieveImagePipeModule,
    IconLabelsModule,
    RouterModule,
    AddResponsiveClassModule,
  ],
  exports: [TileComponent],
})
export class TileModule {}
