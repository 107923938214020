import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditUserDialogData, AddEditUserDialogResult } from './add-edit-user-dialog.model';

@Component({
  selector: 'sywa-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss'],
})
export class AddEditUserDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public addUserDialogData: AddEditUserDialogData,
    private dialogRef: MatDialogRef<AddEditUserDialogComponent>
  ) {}

  close(hasBeenAddedOrEdited = false): void {
    const result: AddEditUserDialogResult = { hasBeenAddedOrEdited };
    this.dialogRef.close(result);
  }
}
