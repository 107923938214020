import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppConfigModule } from './app-config.module';
import { ApiModule } from 'app/shared/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { authCodeFlowConfig } from './auth-code-flow-config.constant';

function oAuthInitialization(oAuthService: OAuthService): () => Observable<boolean> {
  return (): Observable<boolean> => {
    // oAuthService.events.subscribe((e) => console.log('OAuth event : ', e));
    oAuthService.configure(authCodeFlowConfig);
    return;
  };
}

function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, AppConfigModule, AppRoutingModule, ApiModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: oAuthInitialization,
      deps: [OAuthService],
      multi: true,
    },
    {
      provide: OAuthStorage,
      useFactory: storageFactory,
    },
  ],
})
export class AppModule {}
