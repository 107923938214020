import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ACCEPTED_PICTURE_EXTENSIONS,
  ACCEPTED_PICTURE_SIZE_BYTES,
} from '../components/organisms/dropzone/dropzone.constants';
import { DocumentFileMapper } from '../mappers/document-file.mapper';
import { DocumentFile } from '../models/document-file';
import {
  defaultIntegerRegex,
  defaultMaxLenghtInputText,
  defaultMinInputNumber,
  defaultMinLenghtInputText,
  defaultStringNameRegex,
  defaultLengthSIRET,
  defaultSIRETRegex,
} from '../models/form-validation.constants';

@Component({ template: '' })
export class AbstractFormComponent {
  @Output() canceled = new EventEmitter<void>();
  @Output() uploadedFiles = new EventEmitter<File[]>();

  ACCEPTED_PICTURE_SIZE = ACCEPTED_PICTURE_SIZE_BYTES;
  ACCEPTED_PICTURE_EXTENSIONS = ACCEPTED_PICTURE_EXTENSIONS;
  defaultMaxLenghtInputText = defaultMaxLenghtInputText;
  defaultMinLenghtInputText = defaultMinLenghtInputText;
  defaultLengthSIRET = defaultLengthSIRET;
  defaultSIRETRegex = defaultSIRETRegex;
  defaultMinInputNumber = defaultMinInputNumber;
  defaultIntegerRegex = defaultIntegerRegex;
  defaultStringNameRegex = defaultStringNameRegex;

  documentFiles: DocumentFile[];
  protected retrievePicture$: Observable<HttpResponse<Blob>>;

  constructor(private documentFileMapperAbstract: DocumentFileMapper) {}

  cancel(): void {
    this.canceled.emit();
  }

  handleFilesChange(documentFiles: DocumentFile[]): void {
    const files =
      documentFiles?.map((documentFile: DocumentFile) => this.documentFileMapperAbstract.toFile(documentFile)) || [];
    this.uploadedFiles.emit(files);
  }

  protected retrievePicture(): void {
    if (!this.retrievePicture$) {
      throw new Error('Need to set retrievePicture$ property');
    }

    this.retrievePicture$
      .pipe(map((httpResponse: HttpResponse<Blob>) => this.documentFileMapperAbstract.toModel(httpResponse)))
      .subscribe((documentFile: DocumentFile) => {
        this.documentFiles = [documentFile];
        this.handleFilesChange(this.documentFiles);
      });
  }
}
