import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectFormComponent } from './project-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DropzoneModule } from 'app/shared/components/organisms/dropzone/dropzone.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SelectCountryModule } from 'app/shared/components/molecules/select-country/select-country.module';
import { environment } from 'src/environments/environment';
import { OrganisationSelectModule } from 'app/features/organisation/sub-modules/organisation-select/organisation-select.module';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';
import { DateInputModule } from 'app/shared/components/molecules/date-input/date-input.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule];

@NgModule({
  declarations: [ProjectFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    DropzoneModule,
    SelectCountryModule.forRoot(environment.defaultLanguage),
    OrganisationSelectModule,
    DateInputModule,
    ...MATERIAL_MODULES,
    CheckConnectedUserHasRightModule,
  ],
  exports: [ProjectFormComponent],
})
export class ProjectFormModule {}
