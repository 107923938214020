import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration, ResourceCreateRequestVm, ResourceVm } from 'app/shared/api';
import { API_V2_PATH, ListResult } from 'app/shared/api-overrides/apiV2/variables';
import { Observable, Subject } from 'rxjs';
import Obligation from 'app/features/obligation/models/obligation';
import { Apiv2Service } from 'app/shared/api-overrides/apiV2/apiv2.service';
import { ROUTES } from 'app/core/routes.constants';
import DocumentVersion from 'app/features/documentVersion/models/document-version';
import { ResourceUnitView } from 'app/shared/api-overrides/apiV2/entities/Resource';

@Injectable({
  providedIn: 'root',
})
export class ResourceV2Service extends Apiv2Service {
  private emitter = new Subject<Obligation[]>();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(API_V2_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }

  emit(data: Obligation[]): void {
    this.emitter.next(data);
  }

  getEmitter(): Observable<Obligation[]> {
    return this.emitter.asObservable();
  }
  public getResource(resourceId: string): Observable<ResourceUnitView> {
    return this.httpGet<ResourceUnitView>(
      `${this.configuration.basePath}/${ROUTES.resources}/${encodeURIComponent(String(resourceId))}`
    );
  }

  public getResourceObligations(resourceId: string): Observable<ListResult<Obligation>> {
    if (resourceId === null) {
      throw new Error('Required parameters (resourceId) were null or undefined when calling getResourceObligations.');
    }

    return this.httpGet<ListResult<Obligation>>(
      `${this.configuration.basePath}/${ROUTES.resources}/${encodeURIComponent(String(resourceId))}/${
        ROUTES.obligations
      }`
    );
  }

  public getResourceDocumentVersions(
    resourceId: string,
    params: {
      obligationId?: string;
    }
  ): Observable<ListResult<DocumentVersion>> {
    if (resourceId === null) {
      throw new Error('Required parameters (resourceId) were null or undefined when calling getResourceObligations.');
    }

    return this.httpGet<ListResult<DocumentVersion>>(
      `${this.configuration.basePath}/${ROUTES.resources}/${encodeURIComponent(String(resourceId))}/${
        ROUTES.documentVersions
      }`,
      this.createQueryParameters(params)
    );
  }

  public createResourceV2(resourceCreateRequestVm: ResourceCreateRequestVm): Observable<ResourceVm> {
    if (resourceCreateRequestVm === null || resourceCreateRequestVm === undefined) {
      throw new Error('Required parameter resourceCreateRequestVm was null or undefined when calling createResource.');
    }

    return this.httpPost<ResourceVm>(`${this.configuration.basePath}/${ROUTES.resources}`, resourceCreateRequestVm);
  }
}
