import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailChipInputComponent } from './email-chip-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

const MATERIAL_MODULES = [MatChipsModule, MatInputModule, MatIconModule];

@NgModule({
  declarations: [EmailChipInputComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ...MATERIAL_MODULES],
  exports: [EmailChipInputComponent],
})
export class EmailChipInputModule {}
