import { Country } from '@angular-material-extensions/select-country';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';
import { CountryCode } from './select-country.constants';

const SELECT_COUNTRY_CONFIG = {
  itemsLoadSize: 8,
  nullable: true,
  required: true,
};

@Component({
  selector: 'sywa-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectCountryComponent), multi: true }],
})
export class SelectCountryComponent extends AbstractControlValueAccessor<CountryCode> {
  @Input() language: string;
  @Input() labelKey: string;
  @Input() placeHolderKey: string;
  @Input() disabled = false;
  @Output() updated = new EventEmitter<void>();

  country: Country;
  config = SELECT_COUNTRY_CONFIG;

  writeValue(countryCode: CountryCode): void {
    this.country = this.mapCountryCode(countryCode);
    super.writeValue(countryCode);
  }

  countryChange(country: Country): void {
    this.value = country?.alpha2Code ? country.alpha2Code : null;

    if (country) {
      this.updated.emit();
    }
  }

  private mapCountryCode(countryCode: CountryCode): Country {
    return { name: '', alpha2Code: countryCode, alpha3Code: '', numericCode: '', callingCode: '' };
  }
}
