import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessControlsService } from './api/accessControls.service';
import { BenchmarkService } from './api/benchmark.service';
import { DefaultService } from './api/default.service';
import { DocumentsService } from './api/documents.service';
import { InvitationsService } from './api/invitations.service';
import { NotificationsService } from './api/notifications.service';
import { OrganisationsService } from './api/organisations.service';
import { ProjectsService } from './api/projects.service';
import { ProjectsSiteControlsService } from './api/projectsSiteControls.service';
import { ResourcesService } from './api/resources.service';
import { SuppliersService } from './api/suppliers.service';
import { UserPreferencesService } from './api/userPreferences.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
