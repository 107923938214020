import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewerComponent } from './file-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { ImageViewerDirective } from './image-viewer.directive';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';

@NgModule({
  declarations: [FileViewerComponent, ImageViewerDirective],
  imports: [CommonModule, PdfViewerModule, MatIconModule, AddResponsiveClassModule],
  exports: [FileViewerComponent],
})
export class FileViewerModule {}
