import { Injectable } from '@angular/core';
import { User } from 'app/core/models/user';
import { UserCreateRequestVm, UserUpdateRequestVm } from 'app/shared/api';
import { UserForm } from './user-form.model';

@Injectable({
  providedIn: 'root',
})
export class UserFormMapper {
  fromUser(user: User): UserForm {
    if (user) {
      return {
        username: user.username ? user.username : user.email,
        email: user.email,
        firstName: user.firstname,
        lastName: user.lastname,
        organisationId: user.organisation ? user.organisation.id : '',
      } as UserForm;
    }
  }

  toUserCreateRequestVm(user: UserForm): UserCreateRequestVm {
    if (user) {
      return {
        email: user.email.trim(),
        username: user.email.trim(),
        firstName: user.firstName.trim(),
        lastName: user.lastName.trim(),
        organisationId: user.organisationId,
        role: user.role,
      };
    }
  }

  toUserUpdateRequestVm(user: UserForm): UserUpdateRequestVm {
    if (user) {
      return {
        email: user.email?.trim(),
        username: user.username?.trim(),
        firstName: user.firstName?.trim(),
        lastName: user.lastName?.trim(),
        role: user.role,
        organisationId: user.organisationId,
      };
    }
  }
}
