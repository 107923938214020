import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, Input, forwardRef } from '@angular/core';
import { CompanyId } from 'company-id-validator';

const CODE_ERROR_FIELD = 'company-id-error';
const COUNTRY_FIELD = 'countrySelect';

@Directive({
  selector: '[sywaCompanyId]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyIdValidator),
      multi: true,
    },
  ],
})
export class CompanyIdValidator implements Validator {
  @Input() countryCode: string;

  validate(control: AbstractControl): ValidationErrors | null {
    try {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      const countryCode = this.countryCode
        ? this.countryCode
        : (control.parent.controls[COUNTRY_FIELD].value as string);
      const companyId = control.value as string;

      if (
        CompanyId.validate(countryCode, companyId) &&
        (countryCode !== 'FR' || CompanyId.info(countryCode, companyId).name === 'SIRET')
      ) {
        return null;
      } else {
        return { [CODE_ERROR_FIELD]: true };
      }
    } catch (e) {
      return null;
    }
  }
}
