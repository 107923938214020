import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentFileTypes } from 'app/shared/models/document-file-types';
import { FileViewerConfig } from './file-viewer.model';
import { ImageViewerDirective } from './image-viewer.directive';

const ZOOM_INTERVAL = 0.1;

@Component({
  selector: 'sywa-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileViewerComponent implements OnChanges, OnDestroy {
  @Input() config: FileViewerConfig;

  src: Uint8Array | SafeResourceUrl;

  zoomLevel = 1;

  FileTypes = DocumentFileTypes;

  @ViewChild(ImageViewerDirective) imgViewer: ImageViewerDirective;

  private blobObjectUrl: string;

  constructor(private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.config && changes.config && changes.config.previousValue !== changes.config.currentValue) {
      if (this.config.fileType === DocumentFileTypes.PDF) {
        await this.config.blob.arrayBuffer().then((content: ArrayBuffer) => (this.src = new Uint8Array(content)));
      } else if (this.config.fileType === DocumentFileTypes.IMG) {
        const urlCreator = window.URL || window.webkitURL;
        this.blobObjectUrl = urlCreator.createObjectURL(this.config.blob);
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobObjectUrl);
        this.imgViewer?.refresh();
      }
      this.cd.markForCheck();
    }
  }

  ngOnDestroy(): void {
    if (this.blobObjectUrl) {
      const urlCreator = window.URL || window.webkitURL;
      urlCreator.revokeObjectURL(this.blobObjectUrl);
    }
  }

  zoomIn(): void {
    this.zoomLevel = this.zoomLevel + ZOOM_INTERVAL;
  }

  zoomOut(): void {
    const nextZoomLevel = this.zoomLevel - ZOOM_INTERVAL;
    this.zoomLevel = nextZoomLevel <= 0.1 ? 0 : nextZoomLevel;
  }
}
