import { Component, Input } from '@angular/core';
import { ROUTES } from 'app/core/routes.constants';
import { NotificationVm } from 'app/shared/api';

@Component({
  selector: 'sywa-notification-alert-message',
  templateUrl: './notification-alert-message.component.html',
  styleUrls: ['./notification-alert-message.component.scss'],
})
export class NotificationAlertMessageComponent {
  @Input() notification: NotificationVm;
  Routes = ROUTES;

  NotificationSubject = NotificationVm.SubjectEnum;
  NotificationAction = NotificationVm.ActionEnum;
}
