import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDialogComponent } from './info-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InfoDialogComponent],
  imports: [CommonModule, TranslateModule],
  exports: [InfoDialogComponent],
})
export class InfoDialogModule {}
