import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RightName } from 'app/core/rights/right-name.model';
import { UserVm } from 'app/shared/api';

@Component({
  selector: 'sywa-user-role-select',
  templateUrl: './user-role-select.component.html',
  styleUrls: ['./user-role-select.component.scss'],
})
export class UserRoleSelectComponent {
  @Input() labelKey = 'user.infos.labels.role';
  @Input() value: UserVm.RolesEnum;
  @Output() selectedRole = new EventEmitter<UserVm.RolesEnum>();

  Roles = UserVm.RolesEnum;
  RightName = RightName;
}
