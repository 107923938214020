import { Injectable } from '@angular/core';
import { NotificationVm, ProjectSummaryVm, SupplierInvitationVm } from 'app/shared/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationMapper {
  invitationToNotification(invitation: SupplierInvitationVm): NotificationVm {
    if (!invitation) {
      return undefined;
    }
    const notification: NotificationVm = {
      subject: NotificationVm.SubjectEnum.Invitation,
      action: NotificationVm.ActionEnum.Creation,
      date: invitation.invitationDate,
      params: {
        invitationId: invitation.id,
        invitationStatus: invitation.status,
        inviterId: invitation.inviter?.id,
        inviterName: invitation.inviter?.name,
        invitedId: invitation.invited?.id,
        projectName: invitation.project?.name,
        projectId: invitation.project?.id,
        invitationDateStart: invitation.dateStart,
        invitationDateEnd: invitation.dateEnd,
      },
    };

    return notification;
  }

  projectToNotification(project: ProjectSummaryVm): NotificationVm {
    if (!project) {
      return undefined;
    }
    const notification: NotificationVm = {
      subject: NotificationVm.SubjectEnum.Project,
      action: NotificationVm.ActionEnum.Information,
      date: '',
      params: {
        projectName: project.name,
        projectId: project.id,
      },
    };

    return notification;
  }

  countEmployeesNotAssignedToNotification(count: number): NotificationVm {
    if (!count) {
      return undefined;
    }
    const notification: NotificationVm = {
      subject: NotificationVm.SubjectEnum.Resource,
      action: NotificationVm.ActionEnum.Information,
      date: '',
      params: {
        count,
      },
    };

    return notification;
  }
}
