import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { RightName } from 'app/core/rights/right-name.model';
import { ROUTES } from 'app/core/routes.constants';
import { NotificationService } from 'app/core/services/notification.service';
import {
  ErrorVm,
  InvitationsService,
  NotificationVm,
  SupplierInvitationUpdateRequestVm,
  SupplierInvitationVm,
} from 'app/shared/api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'sywa-notification-invitation',
  templateUrl: './notification-invitation.component.html',
  styleUrls: ['./notification-invitation.component.scss'],
})
export class NotificationInvitationComponent implements OnInit {
  @Input() notification: NotificationVm;
  @Output() actionDoneEmitter = new EventEmitter<void>();

  currentInvitation: SupplierInvitationVm;
  currentInvitationStatus: SupplierInvitationVm.StatusEnum;

  Routes = ROUTES;
  InvitationStatus = SupplierInvitationUpdateRequestVm.StatusEnum;
  SupplierInvitationStatus = SupplierInvitationVm.StatusEnum;
  NotificationAction = NotificationVm.ActionEnum;
  RightName = RightName;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private notificationService: NotificationService,
    private invitationsService: InvitationsService
  ) {}

  ngOnInit(): void {
    if (
      this.notification?.params &&
      this.notification.action === NotificationVm.ActionEnum.Creation &&
      Object.prototype.hasOwnProperty.call(this.notification.params, 'invitationId')
    ) {
      this.invitationsService
        .getInvitation(this.notification.params['invitationId'] as string, 'body')
        .subscribe((supplierInvitation: SupplierInvitationVm) => {
          this.currentInvitation = supplierInvitation;
          this.currentInvitationStatus = supplierInvitation.status;
        });
    }
  }

  acceptInvitation(): void {
    this.updateInvitationStatus(true);
    this.actionDoneEmitter.emit();
  }

  refuseInvitation(): void {
    this.updateInvitationStatus(false);
    this.actionDoneEmitter.emit();
  }

  private updateInvitationStatus(accepted: boolean): void {
    const request: SupplierInvitationUpdateRequestVm = {
      status: accepted
        ? SupplierInvitationUpdateRequestVm.StatusEnum.Accepted
        : SupplierInvitationUpdateRequestVm.StatusEnum.Refused,
      supplierType: this.currentInvitation.supplierType,
    };

    if (this.notification?.params && Object.prototype.hasOwnProperty.call(this.notification.params, 'invitationId')) {
      this.invitationsService
        .updateInvitation(this.notification.params['invitationId'] as string, request, 'body')
        .subscribe({
          next: () => {
            this.notificationService.notifySuccess(`supplier.invitation.${accepted ? 'accepted' : 'refused'}`);
            this.currentInvitationStatus = accepted
              ? SupplierInvitationVm.StatusEnum.Accepted
              : SupplierInvitationVm.StatusEnum.Refused;
          },
          error: (error: HttpErrorResponse) => {
            const errorVm: ErrorVm = error.error as ErrorVm;
            this.notificationService.notifyError(errorVm.message);
          },
        });
    } else {
      this.notificationService.notifyError('Error: Notification without param invitationId');
    }
  }
}
