import { Pipe, PipeTransform } from '@angular/core';
import { Person } from 'app/shared/models/person';

@Pipe({
  name: 'notificationControllerParamToPerson',
})
export class NotificationControllerParamToPersonPipe implements PipeTransform {
  transform(notificationParams: object): Person {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      firstname: notificationParams['controllerFirstName'],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      lastname: notificationParams['controllerLastname'],
    };
  }
}
