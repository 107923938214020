import { Pipe, PipeTransform } from '@angular/core';
import { BubbleUser } from '../components/atoms/bubble-user/bubble-user.model';
import { UserSummaryVm, UserVm } from '../api';
import { Person } from '../models/person';
import { ResourceUnitView } from 'app/shared/api-overrides/apiV2/entities/Resource';

@Pipe({
  name: 'fullname',
})
export class FullnamePipe implements PipeTransform {
  transform(user: Person | BubbleUser | UserVm | UserSummaryVm | ResourceUnitView): string {
    if ('firstname' in user) {
      return user.firstname ? `${user.firstname} ${user.lastname}` : user.lastname;
    }
    return user.firstName ? `${user.firstName} ${user.lastName}` : user.lastName;
  }
}
