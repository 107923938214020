import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { OrganisationSelectModule } from 'app/features/organisation/sub-modules/organisation-select/organisation-select.module';
import { UserRoleSelectModule } from '../user-role-select/user-role-select.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckConnectedUserHasRightModule } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.module';

const MATERIAL_MODULES = [MatInputModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule];

@NgModule({
  declarations: [UserFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    OrganisationSelectModule,
    UserRoleSelectModule,
    ...MATERIAL_MODULES,
    CheckConnectedUserHasRightModule,
  ],
  exports: [UserFormComponent],
})
export class UserFormModule {}
