import { Pipe, PipeTransform } from '@angular/core';
import { Person } from 'app/shared/models/person';

@Pipe({
  name: 'notificationResourceParamToPerson',
})
export class NotificationResourceParamToPersonPipe implements PipeTransform {
  transform(notificationParams: object): Person {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      firstname: notificationParams['resourceFirstName'],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      lastname: notificationParams['resourceLastName'],
    };
  }
}
