import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoPanelLayoutComponent } from './two-panel-layout.component';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';

@NgModule({
  declarations: [TwoPanelLayoutComponent],
  imports: [CommonModule, AddResponsiveClassModule],
  exports: [TwoPanelLayoutComponent],
})
export class TwoPanelLayoutModule {}
