import { Component, Input } from '@angular/core';
import { NotificationVm } from 'app/shared/api';

@Component({
  selector: 'sywa-notification-document-content',
  templateUrl: './notification-document-content.component.html',
  styleUrls: ['./notification-document-content.component.scss'],
})
export class NotificationDocumentContentComponent {
  @Input() notification: NotificationVm;
}
