import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportResourceFormComponent } from './report-resource-form.component';
import { ProjectSelectModule } from 'app/features/project/sub-modules/project-select/project-select.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateInputModule } from 'app/shared/components/molecules/date-input/date-input.module';
import { ConvertYearToDateFromNowModule } from 'app/shared/pipes/convert-year-to-date-from-now/convert-year-to-date-from-now.module';
import { AddResponsiveClassModule } from 'app/shared/directives/add-responsive-class/add-responsive-class.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OrganisationSelectModule } from 'app/features/organisation/sub-modules/organisation-select/organisation-select.module';

const MATERIAL_MODULES = [MatFormFieldModule, MatInputModule, MatDatepickerModule, MatCheckboxModule];
const SYWA_MODULES = [
  ProjectSelectModule,
  DateInputModule,
  ConvertYearToDateFromNowModule,
  AddResponsiveClassModule,
  OrganisationSelectModule,
];

@NgModule({
  declarations: [ReportResourceFormComponent],
  imports: [CommonModule, FormsModule, TranslateModule, ...MATERIAL_MODULES, ...SYWA_MODULES],
  exports: [ReportResourceFormComponent],
})
export class ReportResourceFormModule {}
