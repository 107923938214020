import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileModule } from 'app/shared/components/molecules/tile/tile.module';
import { OrganisationTileComponent } from './organisation-tile.component';

@NgModule({
  declarations: [OrganisationTileComponent],
  imports: [CommonModule, TileModule],
  exports: [OrganisationTileComponent],
})
export class OrganisationTileModule {}
