import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsSameAsConnectedUserOrganisationPipe } from './is-same-as-connected-user-organisation.pipe';

@NgModule({
  declarations: [IsSameAsConnectedUserOrganisationPipe],
  imports: [CommonModule],
  exports: [IsSameAsConnectedUserOrganisationPipe],
})
export class IsSameAsConnectedUserOrganisationPipeModule {}
