import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimeInputComponent } from './datetime-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';

import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import 'moment/locale/fr'; // TODO : https://redfroggy.atlassian.net/browse/SYWA-655
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';

const MATERIAL_MODULES = [
  MatInputModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatMomentDatetimeModule,
  MatDatetimepickerModule,
];

@NgModule({
  declarations: [DatetimeInputComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, ...MATERIAL_MODULES],
  exports: [DatetimeInputComponent],
})
export class DatetimeInputModule {}
