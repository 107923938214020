import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileViewerConfig } from 'app/shared/components/organisms/file-viewer/file-viewer.model';
import { DocumentFileTypes } from 'app/shared/models/document-file-types';
import { ResponsiveService } from 'app/shared/services/responsive.service';
import { ResourceUnitView } from 'app/shared/api-overrides/apiV2/entities/Resource';

@Component({
  selector: 'sywa-add-edit-resource',
  templateUrl: './add-edit-resource.component.html',
  styleUrls: ['./add-edit-resource.component.scss'],
})
export class AddEditResourceComponent {
  @Input() organisationId: string;
  @Input() resource?: ResourceUnitView;

  @Output() added = new EventEmitter<string>();
  @Output() canceled = new EventEmitter<void>();

  fileViewerConfigs: FileViewerConfig[] = [];

  constructor(public responsiveService: ResponsiveService) {}

  submitSucceeded(resourceId: string): void {
    this.added.emit(resourceId);
  }

  cancel(): void {
    this.canceled.emit();
  }

  updateFileViewerConfigs(files: File[]): void {
    this.fileViewerConfigs = files.map((file: File) => ({
      blob: file,
      fileType: DocumentFileTypes.IMG,
    }));
  }
}
