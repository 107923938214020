import { UserVm } from 'app/shared/api';
import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserMapper {
  toModel(userApi: UserVm): User {
    if (!userApi) {
      return undefined;
    }

    const {
      firstName,
      lastName,
      email,
      username,
      createdDate,
      updatedDate,
      userId,
      roles,
      organisation,
      projectsId,
      preferences,
    } = userApi;

    return {
      id: userId,
      firstname: firstName,
      lastname: lastName,
      email,
      username,
      createdDate,
      updatedDate,
      roles,
      organisation,
      projectsId,
      preferences,
    };
  }
}
