import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NotificationDocumentExpirationContentComponent } from './notification-document-expiration-content.component';

@NgModule({
  declarations: [NotificationDocumentExpirationContentComponent],
  imports: [CommonModule, TranslateModule, RouterModule],
  exports: [NotificationDocumentExpirationContentComponent],
})
export class NotificationDocumentExpirationContentModule {}
