import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ErrorVm } from 'app/shared/api';
import { RouteHelper } from 'app/shared/helpers/route.helper';
import { catchError, from, map, Observable, of } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private oauthService: OAuthService,
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    // Get final target of route tree and check if is a public route
    const target = RouteHelper.getTargetChild(route);
    if (target.data.isPublic === true) {
      return of(true);
    }

    const retrieveUserObs: Observable<boolean> = this.userService.retrieveCurrentUser().pipe(
      map(() => true),
      catchError((error: HttpErrorResponse) => {
        const errorVm: ErrorVm = error.error as ErrorVm;
        this.notificationService.notifyError(errorVm?.message);
        return of(false);
      })
    );

    if (this.oauthService.hasValidAccessToken()) {
      return retrieveUserObs;
    }

    return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      map((hasValidTokens: boolean) => {
        if (hasValidTokens) {
          this.oauthService.setupAutomaticSilentRefresh();
        } else {
          from(this.oauthService.tryLogin()).subscribe();
        }
        return hasValidTokens;
      })
    );
  }
}
