import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditUserComponent } from './add-edit-user.component';
import { TwoPanelLayoutModule } from 'app/shared/components/organisms/two-panel-layout/two-panel-layout.module';
import { UserFormModule } from '../user-form/user-form.module';

@NgModule({
  declarations: [AddEditUserComponent],
  imports: [CommonModule, TwoPanelLayoutModule, UserFormModule],
  exports: [AddEditUserComponent],
})
export class AddEditUserModule {}
