import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

const durationSuccess = 3000;
const durationInformation = 3000;
const durationError = 0;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  notifySuccess(
    messageKey = 'notifications.saved',
    actionKey = 'notifications.actions.ok',
    className = 'success-snackbar',
    duration = durationSuccess
  ): void {
    const message = `${this.translateService.instant(messageKey) as string}`;
    const action = `${this.translateService.instant(actionKey) as string}`;
    this.openSnackBar(message, action, className, duration);
  }

  notifyInformation(
    infoMessage = '',
    messageKey = 'notifications.information',
    actionKey = 'notifications.actions.ok',
    className = 'information-snackbar',
    duration = durationInformation
  ): void {
    infoMessage = infoMessage ? ` : ${infoMessage}` : '';
    const message = `${this.translateService.instant(messageKey) as string}${infoMessage}`;
    const action = `${this.translateService.instant(actionKey) as string}`;
    this.openSnackBar(message, action, className, duration);
  }

  notifyError(
    errorMessage = '',
    messageKey = 'notifications.error',
    actionKey = 'notifications.actions.ok',
    className = 'error-snackbar',
    duration = durationError
  ): void {
    errorMessage = errorMessage ? ` : ${errorMessage}` : '';
    const message = `${this.translateService.instant(messageKey) as string}${errorMessage}`;
    const action = `${this.translateService.instant(actionKey) as string}`;
    this.openSnackBar(message, action, className, duration);
  }

  private openSnackBar(message: string, action: string, className: string, duration: number): void {
    this.snackBar.open(message, action, {
      duration,
      panelClass: className,
    });
  }
}
