import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Navigation } from 'app/core/models/navigation';
import { RightName } from 'app/core/rights/right-name.model';
import { RightsService } from 'app/core/rights/rights.service';
import { NavigationRepository } from 'app/core/state/navigation.repository';
import { map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'sywa-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  navConfigs: Navigation[];

  currentNavigation$: Observable<Navigation>;
  @Output() closeEmitter = new EventEmitter<void>();

  constructor(
    private router: Router,
    private navigationRepository: NavigationRepository,
    private rightsService: RightsService
  ) {}

  ngOnInit(): void {
    this.currentNavigation$ = this.navigationRepository.getCurrentNavigation();
    this.navigationRepository
      .getNavConfigs()
      .pipe(
        map((navConfigs: Navigation[]) =>
          navConfigs.filter((navConfig: Navigation) => navConfig.id !== 'employees' && navConfig.id !== 'organisations')
        ),
        switchMap((navConfigs: Navigation[]) =>
          this.rightsService.connectedUserHasRight(RightName.CAN_NAVIGATE) ? of(navConfigs) : of([])
        ),
        switchMap((navConfigs: Navigation[]) => {
          if (navConfigs.length === 0) {
            return of([]);
          }
          return this.rightsService
            .canNavigateToDashboard()
            .pipe(
              map((canNavigateToDashboard: boolean) =>
                canNavigateToDashboard
                  ? navConfigs
                  : navConfigs.filter((navConfig: Navigation) => navConfig.id !== 'dashboard')
              )
            );
        })
      )
      .subscribe((navConfigs: Navigation[]) => (this.navConfigs = navConfigs));
  }

  close(): void {
    this.closeEmitter.emit();
  }

  async navigate(route: string): Promise<void> {
    this.close();
    await this.router.navigate([route]);
  }
}
