import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckConnectedUserHasRightPipe } from './check-connected-user-has-right.pipe';

@NgModule({
  declarations: [CheckConnectedUserHasRightPipe],
  imports: [CommonModule],
  exports: [CheckConnectedUserHasRightPipe],
})
export class CheckConnectedUserHasRightModule {}
