import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserMapper } from 'app/core/mappers/user.mapper';
import { User } from 'app/core/models/user';
import { RightName } from 'app/core/rights/right-name.model';
import { LoaderService } from 'app/core/services/loader.service';
import { NotificationService } from 'app/core/services/notification.service';
import { AuthRepository } from 'app/core/state/auth.repository';
import {
  ErrorVm,
  OrganisationVm,
  UserCreateRequestVm,
  UsersService,
  UserUpdateRequestVm,
  UserVm,
} from 'app/shared/api';
import { defaultMaxLenghtInputText, defaultStringNameRegex } from 'app/shared/models/form-validation.constants';
import { finalize, Observable, ObservableInput, of, switchMap } from 'rxjs';
import { UserFormMapper } from './user-form-mapper';
import { DEFAULT_USER_FORM } from './user-form.constants';
import { UserForm } from './user-form.model';

@Component({
  selector: 'sywa-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnChanges {
  @Input() organisation: OrganisationVm;
  @Input() userToEdit: User;
  @Input() userVm: UserVm;

  userForm: UserForm = {} as UserForm;
  selectedOrganisation: OrganisationVm;
  submitted = false;
  isEditMode = false;
  toEnableUser: boolean;

  defaultMaxLenghtInputText = defaultMaxLenghtInputText;
  defaultStringNameRegex = defaultStringNameRegex;

  RightName = RightName;

  @Output() submitSucceeded = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  constructor(
    private userFormMapper: UserFormMapper,
    private authRepository: AuthRepository,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private userMapper: UserMapper
  ) {
    this.userForm = DEFAULT_USER_FORM;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userToEdit?.currentValue) {
      this.isEditMode = true;
      this.userForm = this.userFormMapper.fromUser(this.userToEdit);
    }

    if (changes.userVm?.currentValue) {
      this.isEditMode = true;
      this.userToEdit = this.userMapper.toModel(this.userVm);
      this.userForm = this.userFormMapper.fromUser(this.userToEdit);
      this.toEnableUser = this.userVm.enabled;
    }

    if (changes.organisation?.currentValue) {
      this.selectedOrganisation = this.organisation;
    }
  }

  ngOnInit(): void {
    if (!this.isEditMode) {
      this.selectedOrganisation = this.authRepository.getCurrentUserOrganisationSnapshot();
    }
  }

  userSubmit(): void {
    this.submitted = true;

    let request$: Observable<string>;

    if (this.isEditMode) {
      this.userForm.organisationId = this.selectedOrganisation?.id.toString();
      this.userForm.role = this.userToEdit.roles?.length ? this.userToEdit.roles[0] : undefined; // TODO ticket https://redfroggy.atlassian.net/browse/SYWA-1309
      request$ = of(this.userFormMapper.toUserUpdateRequestVm(this.userForm)).pipe(
        switchMap(
          (userUpdate: UserUpdateRequestVm) =>
            this.usersService.updateUser(this.userToEdit.id, userUpdate, 'body') as ObservableInput<UserVm>
        ),
        switchMap((userUpdated: UserVm) => {
          if (userUpdated.enabled !== this.toEnableUser) {
            return this.toEnableUser
              ? this.usersService.enableUser(this.userToEdit.id, 'body')
              : this.usersService.disableUser(this.userToEdit.id, 'body');
          } else {
            return of(userUpdated);
          }
        }),
        switchMap(() => of(void 0))
      );
    } else {
      this.userForm.organisationId = this.selectedOrganisation.id.toString();
      request$ = of(this.userFormMapper.toUserCreateRequestVm(this.userForm)).pipe(
        switchMap((userCreate: UserCreateRequestVm) => this.usersService.createUser(userCreate, 'body')),
        switchMap(() => of(void 0))
      );
    }

    this.loaderService.open();
    request$.pipe(finalize(() => this.loaderService.close())).subscribe({
      next: () => {
        this.notificationService.notifySuccess();
        this.submitSucceeded.emit();
      },
      error: (error: HttpErrorResponse) => {
        this.submitted = false;
        const errorVm: ErrorVm = error.error as ErrorVm;
        this.notificationService.notifyError(errorVm.message);
      },
    });
  }

  cancel(): void {
    this.canceled.emit();
  }
}
