import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollComponent } from './infinite-scroll.component';
import { InfiniteScrollModule as NgxInfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [InfiniteScrollComponent],
  imports: [CommonModule, NgxInfiniteScrollModule, MatIconModule],
  exports: [InfiniteScrollComponent],
})
export class InfiniteScrollModule {}
