import { environment } from 'src/environments/environment';

export enum PictureEntity {
  RESOURCE = 'resources',
  PROJECT = 'projects',
  ORGANISATION = 'organisations',
}

type getPictureProps = {
  entityId: string;
  entityType: PictureEntity;
  pictureId: string;
};

const getPicture = ({ pictureId, entityType, entityId }: getPictureProps): string =>
  `${environment.s3Bucket}/${entityType}/${entityId}/${pictureId}`;
export default getPicture;
