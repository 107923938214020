import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationControlContentComponent } from './notification-control-content.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FullnamePipeModule } from 'app/shared/pipes/fullname-pipe.module';
import { NotificationResourceParamToPersonPipe } from './notification-resource-param-to-person.pipe';
import { NotificationControllerParamToPersonPipe } from './notification-controller-param-to-person.pipe';

@NgModule({
  declarations: [
    NotificationControlContentComponent,
    NotificationResourceParamToPersonPipe,
    NotificationControllerParamToPersonPipe,
  ],
  imports: [CommonModule, RouterModule, TranslateModule, FullnamePipeModule],
  exports: [NotificationControlContentComponent],
})
export class NotificationControlContentModule {}
