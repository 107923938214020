import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractEditComponent } from 'app/shared/abstract-classes/abstract-edit-component';
import { ProjectVm } from 'app/shared/api';
import { DocumentFileTypesMapper } from 'app/shared/mappers/document-file-types.mapper';
import { ResponsiveService } from 'app/shared/services/responsive.service';

@Component({
  selector: 'sywa-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.scss'],
})
export class AddEditProjectComponent extends AbstractEditComponent {
  @Input() project: ProjectVm;

  @Output() projectAddedOrEdited = new EventEmitter<string>();

  constructor(public responsiveService: ResponsiveService, private documentsFileTypesMapper: DocumentFileTypesMapper) {
    super(documentsFileTypesMapper);
  }

  submitSucceeded(projectId: string): void {
    this.projectAddedOrEdited.emit(projectId);
  }
}
