import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentFile } from '../models/document-file';
import { DocumentFileTypesMapper } from './document-file-types.mapper';

@Injectable({
  providedIn: 'root',
})
export class DocumentFileMapper {
  constructor(private documentFileTypesMapper: DocumentFileTypesMapper) {}

  toModel(httpResponse: HttpResponse<Blob>, fileId?: string): DocumentFile {
    if (!httpResponse) {
      return undefined;
    }

    const contentTypeHeader = httpResponse.headers.get('Content-Type');
    const type = this.documentFileTypesMapper.toModel(contentTypeHeader);

    const name = httpResponse.headers.get('Content-Disposition');

    return {
      id: fileId,
      name,
      type,
      content: httpResponse.body,
    };
  }

  toFile(documentFile: DocumentFile): File {
    if (!documentFile) {
      return undefined;
    }

    return new File([documentFile.content], documentFile.name, {
      type: documentFile.content.type,
    });
  }

  fromFile(file: File): DocumentFile {
    if (!file) {
      return undefined;
    }
    return {
      name: file.name,
      type: this.documentFileTypesMapper.toModel(file.type),
      content: file,
    };
  }
}
