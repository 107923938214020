import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'bytes' })
export class BytesPipe implements PipeTransform {
  private dictionary: Array<{ max: number; type: string }> = [
    { max: 1024, type: 'B' },
    { max: 1048576, type: 'KB' },
    { max: 1073741824, type: 'MB' },
    { max: 1.0995116e12, type: 'GB' },
  ];

  constructor(protected readonly translateService: TranslateService) {}

  transform(value: number): string | number {
    const format = this.dictionary.find((d) => value < d.max) || this.dictionary[this.dictionary.length - 1];
    const size = Math.floor(value / (format.max / 1024));
    return this.translateService.instant('unity.' + format.type, { size }) as string;
  }
}
