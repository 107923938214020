// Handle this in the backend > https://redfroggy.atlassian.net/browse/SYWA-1100

import { UserVm } from 'app/shared/api';
import { RightName } from './right-name.model';
import { Right } from './right.model';

export const RIGHTS: Right[] = [
  {
    name: RightName.READ_ANY_DOCUMENT_FORM,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.READ_DOCUMENT_FORMS_OF_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.READ_DOCUMENT_AUTO_CONTROL_DATA,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.LOCK_DOCUMENT,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UNLOCK_DOCUMENT,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.CREATE_DOCUMENT_FOR_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
      UserVm.RolesEnum.Employee,
    ],
  },
  {
    name: RightName.DELETE_ANY_DOCUMENT,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.DELETE_DOCUMENT_FOR_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.UPDATE_DOCUMENT_TO_RENEW_STATE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UPDATE_ANY_DOCUMENT_TO_CONTROL_STATE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.UPDATE_DOCUMENT_TO_CONTROL_STATE_OF_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Manager, UserVm.RolesEnum.ManagerOperation],
  },
  {
    name: RightName.UPDATE_ANY_DOCUMENT_TO_COMPLETE_STATE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UPDATE_DOCUMENT_TO_COMPLETE_STATE_OF_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.UPDATE_DOCUMENT_TO_VALIDATED_STATE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UPDATE_DOCUMENT_REFUSED_STATE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UPDATE_DATAS_OF_ANY_DOCUMENTS,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UPDATE_DATAS_OF_ANY_DOCUMENTS_FROM_ANY_DOCUMENT_STATUS,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.UPDATE_DATAS_OF_DOCUMENTS_OF_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.UPDATE_FILES_OF_ANY_DOCUMENT,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.UPDATE_FILES_OF_DOCUMENTS_OF_OWN_ORGANISATION_RESOURCE,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.CREATE_RESOURCE_FOR_OWN_COMPANY,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.EDIT_ANY_RESOURCE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.EDIT_RESOURCE_OF_OWN_COMPANY,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.DELETE_ANY_RESOURCE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.DELETE_RESOURCE_OF_OWN_COMPANY,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.INVITE_ORGANISATION,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.CREATE_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.EDIT_ANY_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.EDIT_OWN_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.EDIT_BTPCARD_REQUIRED_ANY_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.EDIT_TEMPORARY_EMPLOYMENT_ANY_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.ACCEPT_ANY_OPERATION_INVITATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.ACCEPT_OPERATION_INVITATION_FOR_OWN_ORGANISATION,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.ADD_ANY_RESOURCE_TO_OPERATION_OF_ITS_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.ADD_OWN_ORGANISATION_RESOURCE_TO_OPERATION_OF_ITS_ORGANISATION,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.CREATE_OPERATION_FOR_ANY_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.CREATE_OPERATION_FOR_OWN_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.EDIT_ANY_OPERATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.EDIT_ANY_OPERATION_IF_IS_CLIENT,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.EDIT_OWN_OPERATION_IF_IS_CLIENT,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.CREATE_USER_FOR_ANY_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.CREATE_USER_FOR_OWN_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.CREATE_ADMIN_OR_SUPERADMIN_USER,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.EDIT_ANY_USER,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.ADD_USER_TO_ANY_OPERATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.ADD_USER_TO_OPERATION_OF_OWN_ORGANISATION,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
  {
    name: RightName.EDIT_INVITATION_DATES,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
    ],
  },
  {
    name: RightName.CAN_NAVIGATE,
    authorizedRoles: [
      UserVm.RolesEnum.Superadmin,
      UserVm.RolesEnum.Admin,
      UserVm.RolesEnum.Manager,
      UserVm.RolesEnum.ManagerOperation,
      UserVm.RolesEnum.Employee,
    ],
  },
  {
    name: RightName.DISABLE_EMAIL_NOTIFICATION_DOCUMENTSTATUS_TOCONTROL,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin],
  },
  {
    name: RightName.SITE_CONTROL_ANY_RESOURCE,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.EXPORT_ANY_PROJECT_RESOURCES,
    authorizedRoles: [UserVm.RolesEnum.Superadmin],
  },
  {
    name: RightName.CAN_SEE_RESOURCES_OBLIGATIONS,
    authorizedRoles: [UserVm.RolesEnum.Superadmin, UserVm.RolesEnum.Admin, UserVm.RolesEnum.Manager],
  },
];
