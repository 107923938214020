import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  registerIcons(): void {
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/symbol-defs.svg'));
    this.iconRegistry.addSvgIcon(
      'sywa-zoom-out',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoom-out.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-zoom-in',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoom-in.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-envelop',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/envelop.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-operation',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/operation.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-hands-shake',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hands-shake.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-suitcase',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/suitcase.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-burger-menu',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/burger-menu.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-resource',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/resource.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-dashboard',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-supplier',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/supplier.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-document',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/document.svg')
    );
    this.iconRegistry.addSvgIcon('sywa-user', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'));
    this.iconRegistry.addSvgIcon('sywa-cross', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cross.svg'));
    this.iconRegistry.addSvgIcon('sywa-check', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg'));
    this.iconRegistry.addSvgIcon(
      'sywa-exclamation-mark',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exclamation-mark.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-whistle',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/whistle.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-assign',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/assign.svg')
    );
    this.iconRegistry.addSvgIcon('sywa-alert', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert.svg'));
    this.iconRegistry.addSvgIcon(
      'sywa-location',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/location.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-employment',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/employment.svg')
    );
    this.iconRegistry.addSvgIcon('sywa-sort', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sort.svg'));
    this.iconRegistry.addSvgIcon(
      'sywa-filter',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-document-user',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/document-user.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-picture',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/picture.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sywa-notif-document-status',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notif-document-status.svg')
    );
    this.iconRegistry.addSvgIcon('sywa-help', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/help.svg'));
  }
}
