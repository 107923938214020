import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/components/molecules/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'app/shared/components/molecules/confirm-dialog/confirm-dialog.model';
import { InfoDialogComponent } from 'app/shared/components/molecules/info-dialog/info-dialog.component';
import { InfoDialogData } from 'app/shared/components/molecules/info-dialog//info-dialog.model';
import { ReasonDialogComponent } from 'app/shared/components/molecules/reason-dialog/reason-dialog.component';
import { ReasonDialogData } from 'app/shared/components/molecules/reason-dialog/reason-dialog.model';
import { Observable } from 'rxjs';

export const FULLSCREEN_DIALOG_CONFIG = {
  width: '100vw',
  height: '100vh',
  autofocus: false,
  panelClass: 'fullscreen-dialog',
  hasBackdrop: false,
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  open<T, D = unknown, R = unknown>(component: ComponentType<T>, data?: D): Observable<R> {
    return this.matDialog
      .open<T, D, R>(component, {
        data,
        width: FULLSCREEN_DIALOG_CONFIG.width,
        height: FULLSCREEN_DIALOG_CONFIG.height,
        maxWidth: FULLSCREEN_DIALOG_CONFIG.width,
        maxHeight: FULLSCREEN_DIALOG_CONFIG.height,
        autoFocus: FULLSCREEN_DIALOG_CONFIG.autofocus,
        panelClass: FULLSCREEN_DIALOG_CONFIG.panelClass,
        hasBackdrop: FULLSCREEN_DIALOG_CONFIG.hasBackdrop,
      })
      .afterClosed();
  }

  confirm(title: string, message: string): Observable<boolean> {
    return this.matDialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        data: { title, message },
      })
      .afterClosed();
  }

  info(title: string, message: string): Observable<void> {
    return this.matDialog
      .open<InfoDialogComponent, InfoDialogData, void>(InfoDialogComponent, {
        data: { title, message },
      })
      .afterClosed();
  }

  askReason<R = unknown>(title: string, message: string): Observable<R> {
    return this.matDialog
      .open<ReasonDialogComponent, ReasonDialogData, R>(ReasonDialogComponent, {
        data: { title, message },
      })
      .afterClosed();
  }
}
