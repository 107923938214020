import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DocumentStatusHistoryVm, NotificationVm, SupplierInvitationUpdateRequestVm } from 'app/shared/api';

@Component({
  selector: 'sywa-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss'],
})
export class NotificationSnackbarComponent {
  InvitationStatus = SupplierInvitationUpdateRequestVm.StatusEnum;
  DocumentStatus = DocumentStatusHistoryVm.StatusEnum;
  NotificationSubject = NotificationVm.SubjectEnum;
  NotificationAction = NotificationVm.ActionEnum;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationVm,
    private snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>
  ) {}

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
