import { Inject, Injectable, InjectionToken } from '@angular/core';
import { createStore, select, Store, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { Navigation } from '../models/navigation';

interface NavigationProps {
  currentNavigation: Navigation | null;
  navConfigs: Navigation[] | null;
}

const navigationStoreName = 'navigation';

export type NavigationStore = Store<{ name: string; state: NavigationProps; config: undefined }, NavigationProps>;
export const NavigationStoreToken = new InjectionToken<NavigationStore>('NavigationStore', {
  providedIn: 'root',
  factory: (): NavigationStore =>
    createStore(
      { name: navigationStoreName },
      withProps<NavigationProps>({ currentNavigation: null, navConfigs: null })
    ),
});

@Injectable({
  providedIn: 'root',
})
export class NavigationRepository {
  constructor(@Inject(NavigationStoreToken) private navigationStore: NavigationStore) {}

  getCurrentNavigationSnapshot(): Navigation {
    return this.navigationStore.state.currentNavigation;
  }

  getCurrentNavigation(): Observable<Navigation> {
    return this.navigationStore.pipe(select((state) => state.currentNavigation));
  }

  updateCurrentNavigation(navigation: Navigation): void {
    this.navigationStore.update((state) => ({
      ...state,
      currentNavigation: navigation,
    }));
  }

  getNavConfigs(): Observable<Navigation[]> {
    return this.navigationStore.pipe(select((state) => state.navConfigs));
  }

  updateNavConfigs(navigations: Navigation[]): void {
    this.navigationStore.update((state) => ({
      ...state,
      navConfigs: navigations,
    }));
  }
}
