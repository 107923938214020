import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'convertYearToDateFromNow',
})
export class ConvertYearToDateFromNowPipe implements PipeTransform {
  transform(year: number): string {
    return moment().add({ years: -year }).toISOString();
  }
}
