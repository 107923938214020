import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { RightName } from 'app/core/rights/right-name.model';
import { LoaderService } from 'app/core/services/loader.service';
import { NotificationService } from 'app/core/services/notification.service';
import { AuthRepository } from 'app/core/state/auth.repository';
import { employerRankMax, employerRankMin } from 'app/features/organisation/models/organisation.constants';
import { AbstractFormComponent } from 'app/shared/abstract-classes/abstract-form-component';
import {
  ErrorVm,
  OrganisationVm,
  ProjectCreateRequestVm,
  ProjectsService,
  ProjectUpdateRequestVm,
  ProjectVm,
} from 'app/shared/api';
import { DocumentFileMapper } from 'app/shared/mappers/document-file.mapper';
import { CheckConnectedUserHasRightPipe } from 'app/shared/pipes/check-connected-user-has-right/check-connected-user-has-right.pipe';
import { finalize, Observable, of, switchMap, tap } from 'rxjs';
import { ProjectForm } from './project-form';
import { ProjectFormMapper } from './project-form.mapper';

@Component({
  selector: 'sywa-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
  providers: [CheckConnectedUserHasRightPipe],
})
export class ProjectFormComponent extends AbstractFormComponent implements OnInit, OnChanges {
  @Input() projectToEdit: ProjectVm;
  @Output() submitSucceeded = new EventEmitter<string>();

  projectForm: ProjectForm = {} as ProjectForm;

  internalProjectType: ProjectCreateRequestVm.TypeEnum;
  currentUserOrganisation: OrganisationVm;
  employmentTypes: ProjectCreateRequestVm.AllowedEmploymentTypesEnum[] = [];

  employerRankMin = employerRankMin;
  employerRankMax = employerRankMax;
  minTransmissionDelay = 0;
  minToleranceDelay = 0;

  isSubmitting = false;
  isEditMode = false;

  RightName = RightName;

  @ViewChild('dropzonefiles') private dropzonefiles: NgControl;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    documentFileMapper: DocumentFileMapper,
    private projectsService: ProjectsService,
    private authRepository: AuthRepository,
    private projectFormMapper: ProjectFormMapper,
    public notificationService: NotificationService,
    private checkConnectedUserHasRightPipe: CheckConnectedUserHasRightPipe,
    private loaderService: LoaderService
  ) {
    super(documentFileMapper);
  }

  ngOnInit(): void {
    if (this.checkConnectedUserHasRightPipe.transform(RightName.CREATE_OPERATION_FOR_OWN_ORGANISATION)) {
      this.currentUserOrganisation = this.authRepository.getCurrentUserOrganisationSnapshot();
      this.projectForm.clientId = this.currentUserOrganisation.id;
    }

    this.internalProjectType = 'construction-site';
    this.employmentTypes = this.retrieveEmploymentTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectToEdit) {
      this.isEditMode = !!this.projectToEdit;
      if (this.isEditMode) {
        this.retrievePicture$ = this.projectsService.getProjectPicture(this.projectToEdit.id, 'response');
        this.projectForm = this.projectFormMapper.fromProjectVm(this.projectToEdit);
        this.retrievePicture();
      }
    }
  }

  updateProjectFormClient(client: OrganisationVm): void {
    this.projectForm.clientId = client?.id;
  }

  submitProject(): void {
    let projectId: string;
    this.isSubmitting = true;

    let request$: Observable<ProjectVm>;

    this.projectForm.type ??= this.internalProjectType;

    if (this.isEditMode) {
      request$ = of(this.projectFormMapper.toProjectUpdateRequestVm(this.projectForm)).pipe(
        switchMap((projectUpdateRequestVm: ProjectUpdateRequestVm) =>
          this.projectsService.updateProject(this.projectToEdit.id, projectUpdateRequestVm, 'body')
        )
      );
    } else {
      request$ = of(this.projectFormMapper.toProjectCreateRequestVm(this.projectForm)).pipe(
        switchMap((projectCreateRequestVm: ProjectCreateRequestVm) =>
          this.projectsService.createProject(projectCreateRequestVm, 'body')
        )
      );
    }

    this.loaderService.open();
    request$
      .pipe(
        tap((project: ProjectVm) => (projectId = project.id)),
        switchMap(() =>
          (!this.isEditMode || this.isDropzoneDirty()) && this.documentFiles?.length
            ? this.projectsService.putProjectPicture(projectId, this.documentFiles[0].content)
            : of(void 0)
        ),
        finalize(() => {
          this.loaderService.close();
          this.isSubmitting = false;
          if (projectId) {
            this.submitSucceeded.emit(projectId);
          }
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.notifySuccess();
        },
        error: (error: HttpErrorResponse) => {
          const errorVm: ErrorVm = error.error as ErrorVm;
          this.notificationService.notifyError(errorVm.message);
        },
      });
  }

  isDropzoneDirty(): boolean {
    return this.dropzonefiles.dirty;
  }

  private retrieveEmploymentTypes(): ProjectCreateRequestVm.AllowedEmploymentTypesEnum[] {
    return Object.values(ProjectCreateRequestVm.AllowedEmploymentTypesEnum);
  }
}
