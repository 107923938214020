import { NgModule } from '@angular/core';
import { RetrieveImagePipe } from 'app/shared/pipes/retrieve-image.pipe';

@NgModule({
  declarations: [RetrieveImagePipe],
  exports: [RetrieveImagePipe],
})
/**
 * This module is used to import and configure common modules for all project
 */
export class RetrieveImagePipeModule {}
